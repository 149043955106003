import { useQuery } from '@tanstack/react-query';
import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { Loading } from '../components/Loading';
import { StoreItemElement } from '../components/StoreItemElement';
import { selectAccount } from '../features/login/loginSlice';
import { COMMON_EMPTY_RESPONSE } from '../utils';

import {
    addToCart,
    selectCartItems
} from '../features/cart/cartSlice';
import { getAccountItemPurchases, getStoreItems } from '../services/signalApi';
import { SubscriptionRecurring } from '../types/types';

export interface StoreFrontParams {
    skus?: string[]
    style?: 'cards' | 'list'
    cardStyle?: React.CSSProperties,
    storeRowStyle?: React.CSSProperties,
    includeDescription?: boolean
    featureSkus?: string[]
}


export function StoreFront({
    skus,
    style='cards',
    cardStyle,
    storeRowStyle,
    includeDescription=true,
    featureSkus,
}: StoreFrontParams) {
    const account = useAppSelector(selectAccount);
    const dispatch = useAppDispatch();
    const cartItems = useAppSelector(selectCartItems);
    const [searchParams, setSearchParams] = useSearchParams();

    const cartAction = searchParams.get("action")
    const cartActionItemId = searchParams.get("item_id")
    const cartActionItemRecurring = searchParams.get("recurring")
    const cartActionItemQuantity = searchParams.get("quantity")

    const {
        data: { data: { count: storeItemCount, results: storeItems}} = COMMON_EMPTY_RESPONSE,
        refetch: refetchStoreItems,
        isFetching: isFetchingStoreItems,
    } = useQuery({
        queryKey: ['store', skus],
        staleTime: 1000 * 60 * 15,
        queryFn: () => {
            return getStoreItems({
                ...(skus ? {sku__in: skus.join(',')} : {} ),
            })
        }
    })
    console.log("StoreFront", {storeItemCount, storeItems})

    const {
        data: { data: { count: accountItemPurchasesCount, results: accountItemPurchases}} = COMMON_EMPTY_RESPONSE,
        refetch: refetchAccountItemPurchases,
        isFetching: isFetchingAccountItemPurchases,
    } = useQuery({
        queryKey: ['account_item_purchases'],
        staleTime: 1000 * 60 * 15,
        queryFn: () => {
            return getAccountItemPurchases()
        }
    })

    useEffect(() => {

        if (account && (!cartItems || cartItems.length < 1) && cartAction === "add_to_cart" && cartActionItemId && cartActionItemRecurring !== undefined && cartActionItemRecurring !== null && ["lifetime", "year", "month"].includes(cartActionItemRecurring) && cartActionItemQuantity) {
            const storeItem = (storeItems || []).find((item) => item.id === cartActionItemId)
            if (storeItem) {
                searchParams.delete("action")
                searchParams.delete("item_id")
                searchParams.delete("recurring")
                searchParams.delete("quantity")
                setSearchParams(searchParams)
                dispatch(addToCart({item: storeItem, recurring: cartActionItemRecurring as SubscriptionRecurring, quantity: parseInt(cartActionItemQuantity, 10)}))
            }
        }

    }, [account?.id, JSON.stringify(storeItems)]);

    const existingCartSubscriptionTimeframe = (cartItems || [])[0]?.recurring



    return (
        <Row
            style={storeRowStyle ? storeRowStyle : {marginTop: '30px'}}
            gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, { xs: 16, sm: 16, md: 24, lg: 32 }]}
            // justify="space-around"
            justify="start"
            align="top"
        >
            {isFetchingStoreItems && (
                <Col span={24}>
                    <Loading message='Store' description='Loading Store Items...' style={{}}/>
                </Col>
            )}
            {storeItems?.map((storeItem, idx) => (
                <Col span={style === 'cards' ? 8 : 24} xs={style === 'cards' ? 24 : 24} sm={style === 'cards' ? 24 : 24} md={style === 'cards' ? 12 : 24} lg={style === 'cards' ? 8 : 24} xl={style === 'cards' ? 8 : 24} key={storeItem.id}>
                    <StoreItemElement
                        key={idx}
                        storeItem={storeItem}
                        forceDisable={(
                            cartItems || []).length > 0 &&
                            (cartItems || []).filter((item) => item.item_id === storeItem.id).length === 0
                        }
                        existingCartSubscriptionTimeframe={existingCartSubscriptionTimeframe}
                        existingAccountItemPurchases={(accountItemPurchases || []).filter((aip) => aip.item_id == storeItem.id)}
                        cardStyle={cardStyle}
                        includeDescription={includeDescription}
                        isFeatured={(featureSkus && storeItem.sku ? featureSkus.includes(storeItem.sku) : false)}

                    />
                </Col>
            ))}
        </Row>
    );
}
