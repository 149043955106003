import { Button, Divider, Radio, Carousel, Popconfirm, message, Typography, Input } from 'antd';
import { useState, useEffect, useRef } from 'react';
// import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { ArrowsAltOutlined, ReloadOutlined, UpCircleOutlined, DownCircleOutlined, PauseCircleOutlined, PlayCircleOutlined, CheckCircleOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import type { RadioChangeEvent } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { default as BankNotesIcon } from '../images/banknotes'

import { DateTime } from 'luxon';

import { Table, Tooltip } from 'antd';
import { Link } from "react-router-dom";
import { Workflow, WorkflowBlockStep } from '../types/types';
// import styles from './WorkflowList.module.css';
import { PaginatedTable } from './PaginatedTable';
import { SymbolLink } from './SymbolLink';
import { Loading } from './Loading';
import { DefinedWorkflowButton } from './DefinedWorkflowButton'
import type { InputRef } from 'antd';

import { useAppSelector } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';
import { getWorkflows, deleteWorkflow, editWorkflow } from '../services/signalApi';
import { CreateWorkflowModal } from '../components/CreateWorkflowModal'
import { ConfiguredWorkflowModal } from '../components/ConfiguredWorkflowModal'
import { WorkflowLink } from '../components/WorkflowLink'


interface WorkflowListParams {
    project_id?: string
    showHead?: boolean
    showCreate?: boolean
}


export function WorkflowList({
    project_id,
    showHead=false,
    showCreate=true,
}: WorkflowListParams) {
    // console.log("WorkflowList")
    const account = useAppSelector(selectAccount)
    const [reloadedLast, setReloadedLast] = useState('never');
    const [showCreateWorkflow, setShowCreateWorkflow] = useState(false);
    const [workflowCopySource, setWorkflowCopySource] = useState<Workflow | null>(null);
    const [workflowCount, setWorkflowCount] = useState<number | null>(null);
    const [workflowNameFilter, setWorkflowNameFilter] = useState<string | null>(null);
    const [shownWorkflow, setShownWorkflow] = useState<Workflow | null>(null);


    const inputRef = useRef<InputRef>(null);

    const [reloads, setReloads] = useState(0);
    const createWorkflowClick = () => {
        setWorkflowCopySource(null)
        setShowCreateWorkflow(true)
    }

    const handleCreateCancel = () => {
        setShowCreateWorkflow(false)
        setWorkflowCopySource(null)
    }

    const handleCreateWorkflow = () => {
        setReloads(reloads + 1)
    
    }

    const onSearchSubmit = (value: string) => {
        // console.log("onSearch", {value})
        setWorkflowNameFilter(value)
        setReloads(reloads + 1);
    }

    const delete_workflow = (workflow: Workflow) => {
        // console.log("delete_workflow", {workflow})


        deleteWorkflow(workflow.id).then((res) => {
            if (res.status === 204 ) {
                const response_data = res.data

            } else if (res.status === 400) {
                const response_data = res.data
            } else {
                console.log("failed", {res})
            }

        }).catch((res) => {

        }).finally(() => {
            setReloads(reloads + 1)

        })

    }

    const copy_workflow = (workflow: Workflow) => {
        // console.log("copy_workflow", {workflow})
        setWorkflowCopySource(workflow)
        setShowCreateWorkflow(true)
    }

    const onEnable = (workflow: Workflow) => {
        editWorkflow(workflow.id, {enabled: 1}).then((res) => {
            console.log(res)
            if (res.status === 200) {
                console.log(res.data)
                // message.info("Enabled!")
                setReloads(reloads + 1)
            } else {
                console.log(res.data)
                message.error(`Failure: ${res.data}`)
            }
        })
    };

    const onDisable = (workflow: Workflow) => {
        editWorkflow(workflow.id, {enabled: 0}).then((res) => {
            console.log(res)
            if (res.status === 200) {
                console.log(res.data)
                // message.info("Disabled!")
                setReloads(reloads + 1)
            } else {
                console.log(res.data)
                message.error("Failure!")
            }
        })
    };

   

    const content_table = () => {
        let columns: ColumnsType<Workflow> = [
            {
              title: 'Workflow',
              dataIndex: 'name',
              key: 'name',
              render: (text: string, row: Workflow) => {
                return <WorkflowLink workflow={row}>{row.name}</WorkflowLink>
              },
            },
            ...(project_id ? [] : [{
                title: 'Project',
                dataIndex: 'project_id',
                key: 'project_id',
                render: (text: string, workflow: Workflow) => {
                    return <span>{workflow.project ? <Link to={`/projects/${workflow.project.id}/`}>{workflow.project.name}</Link>: <>-</>}</span>
                },
            }]),
            {
                title: 'Trigger & Steps',
                dataIndex: 'action',
                key: 'action',
                render: (text: string, workflow: Workflow) => {
                    if (workflow.config.triggers.length === 0 || (workflow.config.step_definition?.args.block_steps || []).length === 0) {
                        return <span>-</span>

                    }
                    const trigger_strs = workflow.config.triggers.map((trigger) => {
                        if (trigger.trigger_type === 'form_submit') {
                            return `Form${(trigger.args.submission_params || []).length > 0 ? ':' : ''} ${trigger.args.submission_params.map((p) => p.field).join(', ')}`
                        } else if (trigger.trigger_type === 'timed') {
                            return "Timed"
                        }
                        else {
                            return "None"
                        }
                    }).join(", ")

                    const steps_str = (workflow.config.step_definition?.args.block_steps || []).map((step: WorkflowBlockStep) => {
                            if (step.block_step_type === 'execute_prompt') {
                                // return `Prompt: ${action.args.message}`
                                return `Prompt`
                            }
                            if (step.block_step_type === 'lookup_info_set') {
                                // return `Prompt: ${action.args.message}`
                                return `Lookup`
                            }
                            if (step.block_step_type === 'send_email') {
                                return `Send Email`
                            }
                            if (step.block_step_type === 'set_project_snippet') {
                                return `Set Snippet '${step.args.snippet_name}'`
                            }
                            if (step.block_step_type === 'set_result') {
                                return `Set Result`
                            }
                            return 'Step'
                    }).join(", ")

                    return <span>{trigger_strs} &#8594; {steps_str}</span>
                },
            },
            {
                title: 'Credits',
                dataIndex: 'credit_price',
                key: 'credit_price',
                render: (text: string, row: Workflow) => {
                    return row.credit_price ? <><BankNotesIcon style={{fontSize: "1.4em"}} height={"0.7em"} />&nbsp;{row.credit_price}</> : '-'
                },
              },
            {
                title: '',
                key: 'id',
                dataIndex: 'id',
                width: 130,
                // fixed: 'right',
                render: (value: string, row: Workflow) => (
                    <Space>
                        <Tooltip placement="top" title="Execute Workflow">                        
                            <a onClick={() => {setShownWorkflow(row)}}><PlayCircleOutlined /></a>
                        </Tooltip>

                        {row.enabled === 1 && (
                            <Tooltip placement="top" title="Enabled. Click to pause.">
                                <CheckCircleOutlined style={{ color: '#8bbb11' }} onClick={() => onDisable(row)} />
                            </Tooltip>
                        )}

                        {row.enabled === 0 && (
                            <Tooltip placement="top" title="Disabled. Click to enable.">
                                <PauseCircleOutlined style={{ color: '#df7e2d' }} onClick={() => onEnable(row)}/>
                            </Tooltip>
                        )}



                        <Tooltip placement="top" title="Copy">
                            <CopyOutlined onClick={() => copy_workflow(row)}/>
                        </Tooltip>

                        <Tooltip placement="top" title="Delete">
                            <Popconfirm title={<Typography.Paragraph>Are you sure you want to remove this workflow?</Typography.Paragraph>} okText="Delete" cancelText="Cancel" onConfirm={() => delete_workflow(row)}>
                                <DeleteOutlined />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
  
                )
            }

        ];
        
        const getPagination = (data: any) => {
            const paginationParams = {
                limit: data.pageSize,
                ...(workflowNameFilter ? {name__icontains: workflowNameFilter} : {}),
                ...(data.current > 1 ? {offset: (data.current - 1) * data.pageSize} : {}),
                // offset: (data.current - 1) * data.pageSize,
            }
            // console.log('WorkflowList getPagination', {data, paginationParams})
            return paginationParams
            // return {}
        }

        const fetchData = (data: any) => {
            // console.log("WorkflowList fetchData", {data})
            const getWorkflowsFn = getWorkflows;
            return getWorkflowsFn({
                ...(project_id ? {project_id} : {}),
                ...(data.pagination ? getPagination(data.pagination) : {}),
                ordering: 'name'
            }).then((res) => {
                // console.log("WorkflowList Workflows", {res})
                if (res.status === 200) {
                    setWorkflowCount(res.data.count || 0)
                    return {
                        results: res.data.results || [],
                        count: res.data.count || 0
                    }
                } else {
                    return {
                        results: [],
                        count: 0
                    }
                }
            }).catch(() => {
                return {
                    results: [],
                    count: 0
                }
            })
        }

        
        // console.log("WorkflowList Returning...")

        const emptyDescription = () => {
            return "No Workflows Found"
        }

        return <PaginatedTable<Workflow>
            columns={columns}
            rowKey={(Workflow) => Workflow.id}
            fetchData={fetchData}
            filterValues={{reloadTime: reloadedLast}}
            emptyDescription={emptyDescription()}
            key={reloads}
            size='large'
            pageSize={100}
        />
            
    }

    const head = (
        <Typography.Title level={3} style={{marginTop: 0}}>Workflows</Typography.Title>
    )

    const triggerReload = () => {
        const dt = DateTime.now();
        setReloadedLast(dt.toISO())
    }


    const newButtonComponent = <Button type="primary" onClick={createWorkflowClick} disabled={!!account?.workflow_cap && !!workflowCount && account?.workflow_cap <= workflowCount}>+ New</Button>

    const newButton = !!account?.workflow_cap && !!workflowCount && account?.workflow_cap <= workflowCount ?
        <Tooltip title={`Using ${workflowCount} of ${account.workflow_cap} allowed Workflows`}>{newButtonComponent}</Tooltip> : newButtonComponent


    const leftButtons = (
        <Space style={{marginBottom: '6px'}} wrap>
            <Button type="default" onClick={triggerReload}><ReloadOutlined /></Button>
            {showCreate && newButton}
            <Input.Search
                    placeholder="Workflow name..."
                    allowClear
                    enterButton
                    onSearch={onSearchSubmit}
                    ref={inputRef}
                />
            {showCreate && (
                <DefinedWorkflowButton workflowName={'workflow-suggestion'} buttonText='Get a Suggestion' buttonType='text' />

            )}
        </Space>
    )

    const rightBottons = (
        <Space style={{marginBottom: '6px'}} wrap>
            {showCreate && (
                <DefinedWorkflowButton workflowName={'workflow-suggestion'} buttonText='Get a Suggestion' buttonType='text' />
            )}
            <Input.Search
                    placeholder="Workflow name..."
                    allowClear
                    enterButton
                    onSearch={onSearchSubmit}
                    ref={inputRef}
                />
            {showCreate && newButton}
            <Button type="default" onClick={triggerReload}><ReloadOutlined /></Button>
        </Space>
    )

    return (
        <>
            {showHead ? (<>
                <div style={{float: 'right'}}>{rightBottons}</div>
                {head}
            </>) : (<>
                {leftButtons}
            </>) }
            {content_table()}
            <CreateWorkflowModal
                isOpen={showCreateWorkflow}
                onCancel={handleCreateCancel}
                onCreate={handleCreateWorkflow}
                project_id={project_id}
                workflowCopySource={workflowCopySource ? workflowCopySource : undefined}
            />
            <ConfiguredWorkflowModal
                isOpen={shownWorkflow !== null}
                onCancel={() => setShownWorkflow(null)}
                onComplete={() => {}}
                // onCreate={handleCreateWorkflow}
                workflow={shownWorkflow ? shownWorkflow : undefined}
            />

        </>
    )
    
    
    
}
