import { ShoppingCartOutlined } from '@ant-design/icons';
import type { RadioChangeEvent } from 'antd';
import { Button, Card, Col, Input, Radio, Row, Space, Tooltip, Typography } from 'antd';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';

import {
    addToCart
} from '../features/cart/cartSlice';
import { AccountItemPurchase, StoreItem, SubscriptionRecurring } from '../types/types';
import { effectiveLifetimeUnitPrice, effectiveMonthlyUnitPrice, effectiveYearUnitPrice } from '../utils';

const centeredTextStyling: React.CSSProperties = {
    textAlign: 'center'
}

interface StoreItemParams {
    storeItem: StoreItem
    forceDisable?: boolean
    existingCartSubscriptionTimeframe: SubscriptionRecurring | null
    existingAccountItemPurchases: AccountItemPurchase[]
    cardStyle?: React.CSSProperties
    isFeatured?: boolean
    includeDescription?: boolean
}

export function StoreItemElement({
    storeItem,
    forceDisable=false,
    existingCartSubscriptionTimeframe,
    existingAccountItemPurchases,
    cardStyle,
    isFeatured=false,
    includeDescription=true,
}: StoreItemParams) {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    // const subscriptionTimeframe = 'year'
    const account = useAppSelector(selectAccount);
    const [subscriptionTimeframe, setSubscriptionTimeframe] = useState<SubscriptionRecurring>(storeItem.lifetime_price_cents ? 'lifetime' : 'year');
    const [quantity, setQuantity] = useState<number>(1);

    useEffect(() => {
        if (existingCartSubscriptionTimeframe) {
            if (storeItem.lifetime_price_cents && existingCartSubscriptionTimeframe === 'lifetime' ||
            storeItem.year_price_cents && existingCartSubscriptionTimeframe === 'year' ||
            storeItem.month_price_cents && existingCartSubscriptionTimeframe === 'month'
            ) {
                setSubscriptionTimeframe(existingCartSubscriptionTimeframe)
            }
        }
    }, [existingCartSubscriptionTimeframe])

    const itemButton = () => {
        let buttonText = 'Add to Cart'
        let disabled = false
        let clickFn = () => {
            dispatch(addToCart({item: storeItem, recurring: subscriptionTimeframe, quantity}))
        }

        if (!account) {
            clickFn = () => {
                searchParams.set("auth", "signup")
                searchParams.set("action", "add_to_cart")
                searchParams.set("item_id", storeItem.id)
                searchParams.set("recurring", subscriptionTimeframe)
                searchParams.set("quantity", `${quantity}`)
                setSearchParams(searchParams)
            }
        }

        if (forceDisable) {
            disabled = true
            buttonText = 'Only One Item Allowed'
        }

        if (storeItem.multiple_disallowed === 1 && existingAccountItemPurchases.length > 0) {
            disabled = true
            buttonText = 'Already Purchased'
        }

        if (storeItem.available === 0) {
            disabled = true
            buttonText = 'Not Available'
        }

        if (storeItem.lifetime_price_cents === null && existingCartSubscriptionTimeframe === 'lifetime' ||
            storeItem.year_price_cents === null && existingCartSubscriptionTimeframe === 'year' ||
            storeItem.month_price_cents === null && existingCartSubscriptionTimeframe === 'month'
        ) {
            disabled = true
            buttonText = 'Timeframe Not Available'
        }

        const button = <Button
            icon={<ShoppingCartOutlined />}
            onClick={clickFn}
            disabled={disabled}
            size="large"
            type={'default'}
            style={{width: '100%', borderRadius: '8px', height: '60px'}}
        >
            {buttonText}
        </Button>

        return button

    }

    const onChangeSubscriptionTimeframe = (e: RadioChangeEvent) => {
        // setLocalDateFilter(e.target.value)
        console.log("onChangeSubscriptionTimeframe", e)
        setSubscriptionTimeframe(e.target.value)
    }

    const subscriptionTimeframeOptions = [
        ...(storeItem.lifetime_price_cents ? [{ label: 'One Time', value: 'lifetime', disabled: !!existingCartSubscriptionTimeframe && existingCartSubscriptionTimeframe !== 'lifetime'}] : []),
        ...(storeItem.month_price_cents ? [{ label: 'Monthly', value: 'month', disabled: !!existingCartSubscriptionTimeframe && existingCartSubscriptionTimeframe !== 'month'}] : []),
        ...(storeItem.year_price_cents ? [{ label: 'Annual', value: 'year', disabled: !!existingCartSubscriptionTimeframe && existingCartSubscriptionTimeframe !== 'year'}] : []),
    ]

    // console.log("cardStyle", {cardStyle})

    return <Card title={storeItem.name} bordered={false} style={cardStyle !== undefined ? cardStyle : {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        // boxShadow: '0 4px 8px 0 rgba(180, 180, 180, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        borderRadius: '6px',
        // border: '1px solid #ff0000'
        ...(isFeatured ? {border: '1px solid #e25501'}: {}),
    }}>

        <Typography.Title level={1} style={centeredTextStyling}>{subscriptionTimeframe !== 'lifetime' ? effectiveMonthlyUnitPrice(storeItem, subscriptionTimeframe) : effectiveLifetimeUnitPrice(storeItem, subscriptionTimeframe)}<span style={{fontSize: '0.6em', color: '#888'}}>{subscriptionTimeframe !== 'lifetime' ? '/month' : ''}</span></Typography.Title>
        {/* <Typography.Paragraph style={{fontSize: '0.75em', color: '#888', ...centeredTextStyling}}>Basic offering subject to change.</Typography.Paragraph> */}
        {subscriptionTimeframe !== 'lifetime' && (
            <Typography.Paragraph style={{fontSize: '0.75em', color: '#888', ...centeredTextStyling}}>{effectiveYearUnitPrice(storeItem, subscriptionTimeframe)} / year{subscriptionTimeframe === 'year' ? ' billed annually' : ' billed monthly'}</Typography.Paragraph>
        )}
        {subscriptionTimeframe === 'lifetime' && (
            <Typography.Paragraph style={{fontSize: '0.75em', color: '#888', ...centeredTextStyling}}>&nbsp;</Typography.Paragraph>
            // <Typography.Paragraph style={{fontSize: '0.75em', color: '#888', ...centeredTextStyling}}>One Time</Typography.Paragraph>
        )}
        
        <Row>
            <Col span={24} style={{justifyContent: 'center', display: 'flex'}}>
                {/* <Radio.Group onChange={onChange} defaultValue="annual"> */}
                <Space>
                    {storeItem.multiple_disallowed !== 1 && (
                        <Tooltip placement='top' title='Desired Quantity'>
                            <Input type='number' defaultValue={1} style={{width: '80px'}} onChange={(e) => {setQuantity(parseInt(e.target.value, 10))}} />
                        </Tooltip>
                    )}
                    <Radio.Group options={subscriptionTimeframeOptions} defaultValue={subscriptionTimeframe} value={subscriptionTimeframe} optionType="button" onChange={onChangeSubscriptionTimeframe} />

                </Space>

            </Col>
        </Row>
        <div style={{padding: '12px 20px 30px'}}>
            {itemButton()}

            {/* <Button disabled={false} size="large" style={{width: '100%', borderRadius: '8px', height: '60px'}}>Downgrade</Button> */}
        </div>
        <div style={includeDescription ? {minHeight: "80px"}: {}}>

            {includeDescription && (
                <Typography.Paragraph style={{fontSize: '1.0rem'}}>
                    <ReactMarkdown>{storeItem.description}</ReactMarkdown>
                </Typography.Paragraph>
            )}



            {existingAccountItemPurchases.length > 0 && (
                <div style={{border: '1px solid #888', padding: '8px'}}>
                    <Typography.Paragraph>
                        Note: You already own this.
                    </Typography.Paragraph>
                    <ul>
                        {existingAccountItemPurchases.map((existingAccountItemPurchase, i) => (
                            <li key={i}>
                                {existingAccountItemPurchase.quantity} purchased on {DateTime.fromISO(existingAccountItemPurchase.created_date).toLocaleString(DateTime.DATETIME_MED)}
                            </li>

                        ))}
                    </ul>
                </div>
                
            )}
        </div>
        {/* {item.detail ? <Typography.Paragraph>{item.detail}</Typography.Paragraph> : <></>} */}
    </Card>
}
