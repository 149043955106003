import { Button, Form, Input, Modal, Typography, message, Select } from 'antd';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { selectAccount } from '../features/login/loginSlice'; // Assuming you need the account info, else you can omit this
// import { loadProjectsAsync, selectProjects } from '../features/projects/projectsSlice';
import { createProject } from '../services/signalApi'; // You might need to adjust this to your actual API endpoint
import { loadProjectsAsync, loadPromptPacksAsync, selectProjects, selectStatus as selectProjectsStatus, selectPromptPacks, selectPromptPackStatus } from '../features/projects/projectsSlice';

import { Project } from '../types/types';

import { useAppSelector, useAppDispatch } from '../app/hooks';

interface ProjectModalParams {
    isOpen: boolean
    onCancel: () => void
    onCreate: () => void
    projectCopySource?: Project
}

export function CreateProjectModal({
    isOpen,
    onCancel,
    onCreate,
    projectCopySource,
}: ProjectModalParams) {
    const [form] = Form.useForm();

    const account = useAppSelector(selectAccount);
    const dispatch = useAppDispatch();
    const projects = useAppSelector(selectProjects);
    const promptPacks = useAppSelector(selectPromptPacks);
    const promptPackStatus = useAppSelector(selectPromptPackStatus);
    const [isCreating, setIsCreating] = useState(false);
    const [newProject, setNewProject] = useState<Project | null>(null);

    const empty_projects_arr: Project[] = []
    const prompt_pack_projects: Project[] = (promptPacks || []).reduce((agg, prompt_pack) => {
        return [...agg, ...prompt_pack.projects]
    }, empty_projects_arr)

    useEffect(() => {
        if (projectCopySource) {
            form.setFieldsValue({ name: `${projectCopySource.name} copy`, description: projectCopySource.description || '', ...(projectCopySource ? {preload: projectCopySource.id} : {})})
        } else {
            form.setFieldsValue({ name: '', description: ''})
        }
    }, [JSON.stringify({projectCopySource: projectCopySource})]);


    useEffect(() => {
        if (promptPackStatus === "idle" && promptPacks === null) {
            dispatch(loadPromptPacksAsync())
        }
    }, [promptPackStatus, JSON.stringify({ promptPacks })])

    const handleCancel = () => {
        form.resetFields();
        setNewProject(null);
        setIsCreating(false);
        onCancel();
    }

    const onCreateProjectFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onCreateProjectSubmit = (values: any) => {
        console.log('Success:', {values, account});
        onCreateProject(values);
    };

    const onCreateProject = (values: any) => {
        if (account) {
            setIsCreating(true);
            createProject(
                account.id,
                values.name,
                values.description,
                values.preload,
            ).then((res) => {
                if (res.status === 201) {
                    const response_data = res.data;
                    setNewProject(response_data);
                    setIsCreating(false);
                } else {
                    message.error(res.data);
                    setIsCreating(false);
                }  
            }).catch((err) => {
                console.log("err", {err});
            }).finally(() => {
                setIsCreating(false);
                onCreate();
            });
        }
    }

    const projectFormBody = (
        <Form
            name="project"
            form={form}
            layout='vertical'
            initialValues={{
                preload: 'nothing'
            }}
            onFinish={onCreateProjectSubmit}
            onFinishFailed={onCreateProjectFailed}
            autoComplete="off"
            requiredMark={"optional"}
        >
            {(isCreating && (
                <Typography.Paragraph>Loading...</Typography.Paragraph>
            ))}
            <Typography.Paragraph>Projects organize your workflows and provide common context.</Typography.Paragraph>

            {projectCopySource && (
                <Typography.Paragraph>Note: Snippets and Workflows are not copied over to new projects yet.</Typography.Paragraph>
            )}
            
            {((newProject === null) && (
                <>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Name your project' }]}
                    >
                        <Input placeholder='PyroPrompts'/>
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="description"
                    >
                        <Input.TextArea  style={{ height: 120 }} spellCheck placeholder="PyroPrompts is a prompt management tool that helps users of prompt-based LLM and image AIs organize, share and automate their prompts..." />
                    </Form.Item>

                    <Form.Item
                        label="Preload"
                        name="preload"
                        extra={<>Load with existing snippets and workflows.</>}
                    >
                        <Select>
                            <Select.Option value="nothing">Nothing, empty Project</Select.Option>
                            <Select.Option value="x_content_creation">For 𝕏 Content Creation</Select.Option>
                            <Select.Option value="newsletter">For Newsletter</Select.Option>
                            <Select.Option value="ai_snippets_and_workflows">With AI Suggestion from Description (Beta)</Select.Option>
                            {projects?.map((project) => (
                                <Select.Option value={project.id} key={project.id}>Copy your Project: {project.name}</Select.Option>
                            ))}
                            {prompt_pack_projects?.map((project) => (
                                <Select.Option value={project.id} key={project.id}>Copy from Prompt Pack Project: {project.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{ offset: 0, span: 24 }}
                    >
                        <Button type="primary" htmlType="submit" style={{float: 'right', width: 'unset'}} disabled={
                            isCreating || newProject !== null
                        }>
                            Create Project
                        </Button>
                    </Form.Item>
                </>
            ))}

            {(newProject && (
                <>
                    <Typography.Paragraph>Project Created: <Link to={`/projects/${newProject.id}/`}>{newProject.name}</Link></Typography.Paragraph>
                    <Button type="primary" onClick={handleCancel}>
                        Okay
                    </Button>
                </>
            ))}
        </Form>
    );

    return (
        <Modal title={ projectCopySource ? "Copy Project" : "Create Project" } open={isOpen} footer={null} onCancel={handleCancel}>
            {projectFormBody}
        </Modal>
    );
}
