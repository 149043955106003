import { CheckCircleOutlined, IssuesCloseOutlined, PlayCircleOutlined, RightCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Typography, Collapse } from 'antd';


export interface QAndAItem {
    q: string
    a: string
}

export interface QAndAListParams {
    qas: QAndAItem[]
}

export function QAndAList({qas}: QAndAListParams) {
    return <Collapse defaultActiveKey={qas.map((qa, i) => i)}>
        {qas.map((qa, i) => (
            <Collapse.Panel header={qa.q} key={i}>
                <Typography.Paragraph>{qa.a}</Typography.Paragraph>
            </Collapse.Panel>
        ))}
    </Collapse>
}
