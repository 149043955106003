import { Card, Col, Collapse, Row } from 'antd';
import { useState } from 'react';
import { selectAccount } from '../features/login/loginSlice'; // Assuming you need the account info, else you can omit this
// import { loadProjectsAsync, selectProjects } from '../features/projects/projectsSlice';
import { CreateProjectModal } from './CreateProjectModal';


import { Project } from '../types/types';

import { usePromptPacks } from '../app/apiQueries';
import { useAppSelector } from '../app/hooks';

interface ProjectModalParams {
    onCreate: () => void
    style?: React.CSSProperties
}

export function CreateProjectTemplateList({
    onCreate,
    style={},
}: ProjectModalParams) {

    const account = useAppSelector(selectAccount);
    // const [ projectCopySource, setProjectCopySource ] = useState<string | null>(null);
    const [ projectCopySource, setProjectCopySource ] = useState<Project | null>(null);

    const { prompt_packs } = usePromptPacks(account)

    const empty_projects_arr: Project[] = []
    const prompt_pack_projects: Project[] = (prompt_packs || []).reduce((agg, prompt_pack) => {
        return [...agg, ...prompt_pack.projects]
    }, empty_projects_arr)

    const basic_options = [
        {
            label: 'Content Creation on X',
            value: 'x_content_creation',
            description: 'Daily posts optimized to grow your audience on X',
        },
        {
            label: 'Newsletter',
            value: 'newsletter',
            description: 'Weekly long-form newsletter content to engage your list.',
        }
    ]

    if (prompt_pack_projects.length === 0) {
        return <></>
    }
    //  limit to first 4
    const use_prompt_pack_projects = prompt_pack_projects.length > 4 ? prompt_pack_projects.slice(0, 4) : prompt_pack_projects;

    return (
        <>
            <Collapse defaultActiveKey={'templates'} style={{...style}}>
                <Collapse.Panel header={'Start with a Project Template'} key={'templates'}>
                    <Row
                        gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, { xs: 16, sm: 16, md: 24, lg: 32 }]}
                        // justify="space-around"
                        justify="start"
                        align="top"
                    >
                        {/* {basic_options.map((option, i) => (
                            <Col key={option.value} span={6} onClick={() => {setProjectCopySource(option.value)}}>
                                <Card title={option.label} key={option.value}>
                                    {option.description}
                                </Card>
                            </Col>
                    
                        ))} */}
                        {use_prompt_pack_projects.map((project, i) => (
                            <Col key={project.id} span={6} onClick={() => {setProjectCopySource(project)}} style={{cursor: 'pointer'}}>
                                <Card title={project.name} key={project.id}>
                                    {project.description}
                                </Card>
                            </Col>
                    
                        ))}
                    </Row>
                </Collapse.Panel>
            </Collapse>
            <CreateProjectModal
                isOpen={projectCopySource ? true : false}
                onCreate={() => {
                    onCreate();
                }}
                onCancel={() => setProjectCopySource(null)}
                projectCopySource={projectCopySource ? projectCopySource : undefined}
            />
        </>


    );
}
