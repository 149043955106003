import axios, { AxiosInstance, AxiosResponse } from 'axios'

import {
    Forecast,
    Signal,
    SignalHistory,
    Prompt,
    Workflow,
    WorkflowConfig,
    WorkflowExecution,
    Dashboard,
    DashboardConfig,
    AccountBillingMethod,
    StoreItem,
    StoreCart,
    StoreCartItem,
    AccountItemPurchase,
    SubscriptionRecurring,
    FeatureFlag,
    AccountBio,
    Tag,
    AiChat,
    Project,
    ProjectSnippet,
    NamedWorkflow,
    CreditTransaction,
    PromptPack,
    InfoSet,
    DataSet,
    DataSetRecord,
} from '../types/types'


export const createSignalApiAxiosInstance = (): AxiosInstance =>
    axios.create({
        // baseURL: 'http://localhost:8000',
        baseURL: API_DOMAIN,
        validateStatus: (status) => status >= 200 && status < 500,
        timeout: 60000,
        headers: {
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
        },
    })
export const axiosLoggedOutInstance = createSignalApiAxiosInstance()
axiosLoggedOutInstance.interceptors.request.use((config) => {
    // console.log("request", {config})
    return config
})
axiosLoggedOutInstance.interceptors.response.use((response) => {
    // console.log("response", {response})
    return response
})
export const axiosLoggedInInstance = createSignalApiAxiosInstance()
axiosLoggedInInstance.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem("ACCESS_TOKEN")
    if (!config.headers) {
        config.headers = {}
    }

    // const state = storeRegistry.getState()


    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
    } else {
        console.log("no accessToken, likely error")
    }


    return config
})

axiosLoggedInInstance.interceptors.response.use((response) => {
    // console.log("axiosLoggedInInstance response", {response})
    if (response.status === 401) {
        console.log("axiosLoggedInInstance response 401!", {response})
        const refresh_token = localStorage.getItem("REFRESH_TOKEN")
        // response.request?
        if (refresh_token) {
            console.log("401 need to refresh")
            const reload_attempts = localStorage.getItem("REAUTH_ATTEMPTS")
            setTimeout(() => {
                const second_reload_attempts = localStorage.getItem("REAUTH_ATTEMPTS")
                console.log("401 need to refresh timer", {reload_attempts, second_reload_attempts})
                if (reload_attempts === null || (reload_attempts === second_reload_attempts && parseInt(reload_attempts, 10) < 2)) {
                    const new_reauth_attempts = (reload_attempts ? parseInt(reload_attempts, 10) + 1 : 1).toString()
                    console.log("401 need to refresh reloading...", {reload_attempts, second_reload_attempts, new_reauth_attempts})
                    localStorage.setItem("REAUTH_ATTEMPTS", new_reauth_attempts)
                    setTimeout(() => {
                        window.location.reload();
                    }, 200)
                } else {
                    console.log("401 skipping refresh to avoid loop.", {reload_attempts, second_reload_attempts})
                }

                
            }, Math.floor(Math.random() * 3800)) // Add a bit of time so multiple not setting
        }
        
        // const config = response.config
        // console.log("axiosLoggedInInstance.not_authorized")
        // if (!config._retry) {
        //     console.log("attempting reauthentication")
        //     config._retry = true;
        //     try {
        //         const refresh_token = localStorage.getItem("REFRESH_TOKEN")
        //         if (refresh_token) {
        //             let res = await refreshToken(refresh_token);
        //             // TODO - reload page here?
        //             console.log("reautnenticated with refresh token", {res})
        //             if(res.data.access_token) {
        //                 localStorage.setItem("ACCESS_TOKEN", res.data.access_token)
        //                 if(res.data.refresh_token) {
        //                     localStorage.setItem("REFRESH_TOKEN", res.data.refresh_token)
        //                 }
        //                 console.log("returning new axios logged in instance", {config})
        //                 return axiosLoggedInInstance(config);
        //             }
        
        //         }
    
        //     } catch (err) {
        //         return Promise.reject(err)
        //     }
            
            
        // }
    }
    
    return response
}, async (error) => {
    console.log("axiosLoggedInInstance error response")
    const config = error.config;
    // if(error.response && error.response.status === 400) {
    //     message.error(error.response.data)
    // }
    if(error.response && error.response.status === 401 && !config._retry) {
        console.log("attempting reauthentication")
        config._retry = true;
        try {
            const refresh_token = localStorage.getItem("REFRESH_TOKEN")
            if (refresh_token) {
                let res = await refreshToken(refresh_token);
                // TODO - reload page here?
                console.log("reautnenticated with refresh token", {res})
                if(res.data.access_token) {
                    localStorage.setItem("ACCESS_TOKEN", res.data.access_token)
                    if(res.data.refresh_token) {
                        localStorage.setItem("REFRESH_TOKEN", res.data.refresh_token)
                    }
                    console.log("returning new axios logged in instance", {config})
                    return axiosLoggedInInstance(config);
                }
    
            }

        } catch (err) {
            return Promise.reject(err)
        }
        
        
    }
    return Promise.reject(error)
})

const handleAuthResponse = (response: AxiosResponse) => {
    if (response.status !== 200) {
        console.log(response)
        throw new Error("error")
    }
}

export const getAuthToken  = async (username: string, password: string) => {
    const response = await axiosLoggedOutInstance.post(
        `/oa2/token/`,
        {
            grant_type: "password",
            username: username,
            password: password
        },
        {
            auth: {
                username: APPLICATION_CLIENT_ID,
                password: APPLICATION_CLIENT_SECRET,
            }
        }
    )
    handleAuthResponse(response)
    console.log(response)
    return response

}

export const getOauthClient  = async (client_id: string) => {
    const response = await axiosLoggedOutInstance.get(
        `/api/auth/get_oauth_client/`, { params: {client_id}},
    )
    handleAuthResponse(response)
    console.log(response)
    return response

}

export const refreshToken  = async (refresh_token: string) => {
    const response = await axiosLoggedOutInstance.post(
        `/oa2/token/`,
        {
            grant_type: "refresh_token",
            refresh_token: refresh_token,
        },
        {
            auth: {
                username: APPLICATION_CLIENT_ID,
                password: APPLICATION_CLIENT_SECRET,
            }
        }
    )
    handleAuthResponse(response)
    console.log(response)
    return response

}

export const registerUser  = async (username: string, email: string, password: string, newsletter: '1' | '0') => {
    const response = await axiosLoggedOutInstance.post(
        `/api/auth/register/`,
        {
            username: username,
            email: email,
            password: password,
            newsletter: newsletter,
        }
    )
    // handleAuthResponse(response)
    console.log(response)
    return response

}

export const confirmRegisterUser  = async (username: string, code: string, workflow_execution_id: string | undefined | null) => {
    const response = await axiosLoggedOutInstance.post(
        `/api/auth/confirm_register/`,
        {
            username: username,
            code: code,
            ...(workflow_execution_id ? { workflow_execution_id } : {})
        }
    )
    // handleAuthResponse(response)
    console.log(response)
    return response

}

export const requestResetPassword  = async (username: string) => {
    const response = await axiosLoggedOutInstance.post(
        `/api/auth/request_reset_password/`,
        {
            username: username,
        }
    )
    // handleAuthResponse(response)
    console.log(response)
    return response

}

export const submitResetPassword  = async (username: string, code: string, password: string) => {
    const response = await axiosLoggedOutInstance.post(
        `/api/auth/submit_reset_password/`,
        {
            username: username,
            code: code,
            password: password,
        }
    )
    // handleAuthResponse(response)
    console.log(response)
    return response

}

export const assetsGetWriteUrl  = async (account_id: string, filename: string, content_type: string) => {
    
    const response = await axiosLoggedInInstance.post(
        `/api/assets/get_write_url/`,
        {
            account_id,
            filename,
            content_type,
        }
    )
    // handleAuthResponse(response)
    console.log(response)
    return response

}

export const assetsUpdateProfilePic  = async (account_id: string, url: string) => {
    
    const response = await axiosLoggedInInstance.post(
        `/api/assets/update_profile_pic/`,
        {
            account_id,
            url,
        }
    )
    // handleAuthResponse(response)
    console.log(response)
    return response

}

export const assetsUpdateDataSetSource  = async (account_id: string, data_set_id: string, url: string) => {

    const response = await axiosLoggedInInstance.post(
        `/api/assets/update_data_set_source/`,
        {
            account_id,
            data_set_id,
            url,
        }
    )
    // handleAuthResponse(response)
    console.log(response)
    return response

}

export const dataSetQuery  = async (account_id: string, data_set_id: string, query: string) => {

    const response = await axiosLoggedInInstance.post(
        `/api/data_set/query/`,
        {
            account_id,
            data_set_id,
            query,
        }
    )
    // handleAuthResponse(response)
    console.log(response)
    return response

}

export const redeemCode  = async (account_id: string, code: string) => {
    
    const response = await axiosLoggedInInstance.post(
        `/api/code_redeem/redeem/`,
        {
            account_id,
            code,
        }
    )
    // handleAuthResponse(response)
    console.log(response)
    return response

}





export const oauthAuthorize  = async (
    account_id: string,
    response_type: string,
    client_id: string,
    redirect_uri: string,
    scope: string,
    state: string,
    
) => {
    const response = await axiosLoggedInInstance.post(
        `/api/auth/oauth_authorize/`,
        {
            account_id,
            response_type,
            client_id,
            redirect_uri,
            scope,
            state,
        }
    )
    // handleAuthResponse(response)
    console.log(response)
    return response
}

export const subscribeDataSet  = async (account_id: string, data_set_id: string, data_set_subscription_method_id?: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/data_set_subscriptions/`,
        {
            account_id,
            data_set_id,
            ...(data_set_subscription_method_id ? { data_set_subscription_method_id }: {}),
        }
    )
    // handleAuthResponse(response)
    console.log(response)
    return response

}

export const unsubscribeDataSet = async (account_id: string, data_set_id: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/data_set_subscriptions/`,
        {
            account_id,
            data_set_id,
            unsubscribe: true,
        }
    )
    // handleAuthResponse(response)
    console.log(response)
    return response

}

export const getAccounts  = () => axiosLoggedInInstance.get(`/api/crud/accounts/`)
export const getAccountsLoggedOut = (accessToken: string) => axiosLoggedOutInstance.get(
    `/api/crud/accounts/`,
    {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    }
)

export const getFeatureFlagsLoggedOut = (accessToken: string) => axiosLoggedOutInstance.get(
    `/api/crud/feature_flags/`,
    {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    }
)

export const createApiIntegration = async (account_id: string, name: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/api_integrations/`,
        {
            account_id,
            name,
        }
    )
    console.log("createApiIntegration", response)
    return response

}

export const createApiSimpleIntegration = async (account_id: string, name: string, type: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/api_simple_integrations/`,
        {
            account_id,
            name,
            type,
        }
    )
    console.log("createApiSimpleIntegration", response)
    return response

}

export const createDiscordWebhookIntegration = async (account_id: string, name: string, webhook_url: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/discord_webhook_integrations/`,
        {
            account_id,
            name,
            webhook_url,
        }
    )
    console.log("createDiscordWebhookIntegration", response)
    return response

}

export const createTelegramTokenIntegration = async (account_id: string, name: string, token: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/telegram_token_integrations/`,
        {
            account_id,
            name,
            token,
        }
    )
    console.log("createTelegramTokenIntegration", response)
    return response

}

export const createDataSet = async (data_provider_id: string, name: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/data_sets/`,
        {
            data_provider_id,
            name,
        }
    )
    console.log("createDataSet", response)
    return response

}

interface CreatePromptParams {
    account_id: string
    content: string
    name?: string
    description?: string
    is_public?: number
    platforms?: string[]
}
export const createPrompt = async (params: CreatePromptParams) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/prompts/`,
        {
            ...params,
            is_published: 1
        }
    )
    console.log("createPrompt", response)
    return response

}
interface UpdatePromptParams {
    content?: string
    name?: string
    description?: string
    is_public?: number
    platforms?: string[]
}
export const updatePrompt = async (id: string, params: UpdatePromptParams) => {
    const response = await axiosLoggedInInstance.patch(
        `/api/crud/prompts/${id}/`,
        params
    )
    console.log("updatePrompt", response)
    return response

}


interface CreateAccountPromptMarkParams {
    account_id: string
    prompt_id: string
    mark_type: string
}
export const createAccountPromptMark = async (params: CreateAccountPromptMarkParams) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/account_prompt_marks/`,
        params
    )
    console.log("createAccountPromptMark", response)
    return response

}
export const deleteAccountPromptMark = async (params: CreateAccountPromptMarkParams) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/account_prompt_marks/`,
        {
            ...params,
            remove: true
        }
    )
    console.log("createAccountPromptMark", response)
    return response

}

export const createDataProvider = async (account_id: string, name: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/data_providers/`,
        {
            account_id,
            name,
        }
    )
    console.log("createDataProvider", response)
    return response

}

export const createForecast = async (data_set_id: string, symbol: string, move: number, minutes: number) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/forecasts/`,
        {
            data_set_id,
            symbol,
            move,
            minutes,
        }
    )
    console.log("createForecast", response)
    return response

}

export const createSignal = async (data_set_id: string, symbol: string, value: number, ttl_minutes: number) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/signals/`,
        // `/api/async/signals/`,
        {
            data_set_id,
            symbol,
            value,
            ttl_minutes,
            // Force a context...
            // foo: 2, nice: 1, yeah: -5, okay: 4000, direction: 1,
            // some_really_long_description: 1, some_other_long_one: 5.00, another_longish_one_that_I_keep_adding_to: -27.07372611
        }
    )
    console.log("createSignal", response)
    return response

}

export const createDataSetRecord = async (data_set_id: string, key: string, content: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/data_set_records/`,
        // `/api/async/signals/`,
        {
            data_set_id,
            key,
            value: 1,
            ttl_minutes: 0,
            content,
            // context: JSON.stringify({content})
            // content,
            // Force a context...
            // foo: 2, nice: 1, yeah: -5, okay: 4000, direction: 1,
            // some_really_long_description: 1, some_other_long_one: 5.00, another_longish_one_that_I_keep_adding_to: -27.07372611
        }
    )
    console.log("createSignal", response)
    return response

}




export const createProject = async (account_id: string, name: string, description: string, preload: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/projects/`,
        {
            account_id,
            name,
            description,
            preload,
        }
    )
    console.log("createProject", response)
    return response

}


export const createProjectSnippet = async (project_id: string, name: string, content: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/project_snippets/`,
        {
            project_id,
            name,
            content,
        }
    )
    console.log("createProjectSnippet", response)
    return response

}

export const createWorkflow = async (account_id: string, name: string, config: WorkflowConfig, project_id?: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/workflows/`,
        {
            account_id,
            name,
            config,
            ...(project_id ? {project_id} : {}),
        }
    )
    console.log("createWorkflow", response)
    return response

}


export const triggerWorkflow  = async (account_id: string, workflow_id: string, workflow_form_params?: {[key: string]: string}) => {
    const response = await axiosLoggedInInstance.post(
        `/api/workflow/trigger/`,
        {
            account_id,
            workflow_id,
            workflow_form_params,
        }
    )
    console.log(response)
    return response

}


export const triggerWorkflowLoggedOut  = async (workflow_id: string, workflow_form_params?: {[key: string]: string}) => {
    const response = await axiosLoggedInInstance.post(
        `/api/workflow/no_auth_trigger/`,
        {
            workflow_id,
            workflow_form_params,
        }
    )
    console.log(response)
    return response

}


export const createDashboard = async (account_id: string, name: string, config: DashboardConfig) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/dashboards/`,
        {
            account_id,
            name,
            config,
        }
    )
    console.log("createDashboard", response)
    return response

}

export const createStoreCartWithItem  = async (account_id: string, item_id: string, recurring?: SubscriptionRecurring, quantity?: number) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/store_carts/`,
        {
            account_id,
            item_id,
            ...(recurring ? { recurring } : {}),
            ...(quantity ? { quantity } : {}),
        }
    )
    console.log(response)
    return response

}

export const addToStoreCart  = async (cart_id: string, item_id: string, recurring?: SubscriptionRecurring, quantity?: number) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/store_cart_items/`,
        {
            cart_id,
            item_id,
            ...(recurring ? { recurring } : {}),
            ...(quantity ? { quantity } : {}),
        }
    )
    console.log(response)
    return response

}

interface CartItemUpdateValues {
    quantity?: number
    recurring?: SubscriptionRecurring
}

export const updateStoreCartItem  = async (cart_item_id: string, update_values: CartItemUpdateValues) => {
    const response = await axiosLoggedInInstance.patch(
        `/api/crud/store_cart_items/${cart_item_id}/`,
        update_values
    )
    console.log(response)
    return response

}


export const createAccountBio  = async (account_id: string, bio: string, is_searchable: number = 0) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/account_bios/`,
        {
            account_id,
            bio,
            is_searchable,
        }
    )
    console.log(response)
    return response
}


export const submitNewsletterEmail  = async (email: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/nl/register/`,
        {
            email,
        }
    )
    console.log(response)
    return response

}


export const checkNewsletterSubscription  = async () => {
    const response = await axiosLoggedInInstance.get(
        `/api/nl/check_subscription/`,
    )
    console.log(response)
    return response

}


// post_data = {"account_id": str(account.id), "prompt": prompt_text}

// response = api_client.post("/api/ai/prompt/", post_data, format="json")
// assert response.status_code == 200, response.data
// assert response.data["message"] == "OK", response.data
// assert response.data["prompt_response"] == "heyoo", response.data

export const aiPrompt  = async (account_id: string, prompt: string, model: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/ai/prompt/`,
        {
            account_id,
            prompt,
            model,
        }
    )
    console.log(response)
    return response

}


interface getForecastsRequest {
    data_set?: string
    symbol?: string
    symbol__icontains?: string
    updated_date_gt?: string
    include_public?: boolean
    include_nested_data_set?: boolean
    limit?: number
    ordering?: string
}

interface getDataSetRecordsRequest {
    data_set?: string
    key?: string
    key__icontains?: string
    updated_date_gt?: string
    include_public?: boolean
    include_nested_data_set?: boolean
    limit?: number
    ordering?: string
}

interface getWorkflowsRequest {
    id?: string
    name?: string
    name__icontains?: string
    project_id?: string
    project_id__isnull?: boolean
    limit?: number
    ordering?: string
}

interface getProjectRequest {
    id?: string
    name?: string
    limit?: number
    ordering?: string
}


interface getProjectSnippetRequest {
    id?: string
    name?: string
    content__icontains?: string
    project_id?: string
    limit?: number
    ordering?: string
}

interface getDashboardsRequest {
    id?: string
    name?: string
    limit?: number
    ordering?: string
    is_public?: number
}

interface getDataSetDashboardsRequest extends getDashboardsRequest {
    data_set_id?: string
    include_public?: number
}

interface getStoreCartsRequestParams {
    'status!'?: string
    status?: string
}

interface ListResponse<RecordType extends object> {
    count?: number
    results?: RecordType[]
}

interface AxiosListResponse<RecordType extends object> {
    data: ListResponse<RecordType>
    status: number
}

interface AxiosResourceResponse<RecordType extends object> {
    data: RecordType
    status: number
}

interface dataSetPatchRequestParams {
    name?: string
    is_public?: number
    is_published?: number
    description?: string
}

interface workflowPatchRequestParams {
    name?: string
    enabled?: number
    config?: WorkflowConfig
}

interface projectPatchRequestParams {
    name?: string
    description?: string
}


interface projectSnippetPatchRequestParams {
    name?: string
    content?: string
    description?: string
}

interface dashboardPatchRequestParams {
    name?: string

    config?: DashboardConfig
}

interface getWorkflowExecutionsRequestParams {
    id?: string
    workflow__project_id?: string
    workflow_id?: string
    limit?: number
    ordering?: string
}

interface getDataSetsRequestParams {
    name__icontains?: string
    ordering?: string
    limit?: number
}

interface getPromptsRequest {
    id?: string
    id__in?: string
    account?: string
    username?: string
    name__icontains?: string
    include_public?: boolean
    content__icontains?: string
    ordering?: string
    limit?: number
    offset?: number
    saved?: boolean
    starred?: boolean
    domain_tags?: string
    platform_tags?: string
}

interface getAccountBiosRequest {
    username?: string
}

interface getTagsRequest {
    platform?: string
    category__iexact?: string
    name__iexact?: string
}

interface baseGetPaginatedRequest {
    ordering?: string
    limit?: number
    offset?: number
}

interface getChatsRequest {
    id?: string
    model?: string
    platform?: string
    prompt__icontains?: string
    response__icontains?: string
    source_prompt_id?: string
    source_workflow_execution_id?: string
}

interface getNamedWorkflowsRequest extends baseGetPaginatedRequest{
    name?: string
    name__icontains?: string
    promoted?: number
}

interface getCreditTransactionsRequest extends baseGetPaginatedRequest {
}

interface getPromptPacksRequest extends baseGetPaginatedRequest {
}

interface getStoreItemsRequest extends baseGetPaginatedRequest {
    sku?: string
    sku__in?: string
}

interface getInfoSetsRequest extends baseGetPaginatedRequest {

}



export const getForecasts  = (params: getForecastsRequest): Promise<AxiosListResponse<Forecast>> => axiosLoggedInInstance.get(`/api/crud/forecasts/`, { params })
export const getForecastsLoggedOut  = (params: getForecastsRequest): Promise<AxiosListResponse<Forecast>> => axiosLoggedOutInstance.get(`/api/crud/forecasts/`, { params })
export const getSignals  = (params: getForecastsRequest): Promise<AxiosListResponse<Signal>> => axiosLoggedInInstance.get(`/api/crud/signals/`, { params })
export const getSignalsLoggedOut  = (params: getForecastsRequest): Promise<AxiosListResponse<Signal>> => axiosLoggedOutInstance.get(`/api/crud/signals/`, { params })

export const getDataSetRecords  = (params: getDataSetRecordsRequest): Promise<AxiosListResponse<DataSetRecord>> => axiosLoggedInInstance.get(`/api/crud/data_set_records/`, { params })

export const getSignalHistory  = (params: getForecastsRequest): Promise<AxiosListResponse<SignalHistory>> => axiosLoggedInInstance.get(`/api/crud/signal_history/`, { params })
export const getSignalHistoryLoggedOut  = (params: getForecastsRequest): Promise<AxiosListResponse<SignalHistory>> => axiosLoggedOutInstance.get(`/api/crud/signal_history/`, { params })
export const getSubscriptions = () => axiosLoggedInInstance.get(`/api/crud/data_set_subscriptions/`)
export const getDeliveryMechanisms = () => axiosLoggedInInstance.get(`/api/crud/delivery_mechanisms/`)
export const getDataSets = (params?: getDataSetsRequestParams): Promise<AxiosListResponse<DataSet>> => axiosLoggedInInstance.get(`/api/crud/data_sets/`, { params: params ? params : {} })
export const getDataSetsLoggedOut = (params?: getDataSetsRequestParams): Promise<AxiosListResponse<DataSet>> => axiosLoggedOutInstance.get(`/api/crud/data_sets/`, { params: params ? params : {} })
export const getDataSet = (id: string): Promise<AxiosResourceResponse<DataSet>> => axiosLoggedInInstance.get(`/api/crud/data_sets/${id}/`)
export const getDataSetLoggedOut = (id: string): Promise<AxiosResourceResponse<DataSet>> => axiosLoggedOutInstance.get(`/api/crud/data_sets/${id}/`)
export const getDataProviders = () => axiosLoggedInInstance.get(`/api/crud/data_providers/`, { params: {my_provider: 1} })
export const getApiIntegrations = () => axiosLoggedInInstance.get(`/api/crud/api_integrations/`)
export const getIntegrations = () => axiosLoggedInInstance.get(`/api/crud/integrations/`, { params: {ordering: '-created_date' }})
export const getApiStatus  = () => axiosLoggedOutInstance.get(`/api/api_status/`)
export const deleteApiApplicationIntegration = (id: string) => axiosLoggedInInstance.delete(`/api/crud/api_integrations/${id}/`)
export const deleteApiSimpleIntegration = (id: string) => axiosLoggedInInstance.delete(`/api/crud/api_simple_integrations/${id}/`)
export const deleteDiscordWebhookIntegration = (id: string) => axiosLoggedInInstance.delete(`/api/crud/discord_webhook_integrations/${id}/`)
export const deleteTelegramTokenIntegration = (id: string) => axiosLoggedInInstance.delete(`/api/crud/telegram_token_integrations/${id}/`)
export const editDataSet = (id: string, params: dataSetPatchRequestParams) => axiosLoggedInInstance.patch(`/api/crud/data_sets/${id}/`, params )
export const findDataSetSubscriptionMethod = (id: string) => axiosLoggedInInstance.get(`/api/crud/data_set_subscription_methods/`, {params: {id}})
export const getWorkflows  = (params: getWorkflowsRequest): Promise<AxiosListResponse<Workflow>> => axiosLoggedInInstance.get(`/api/crud/workflows/`, { params })
export const editWorkflow = (id: string, params: workflowPatchRequestParams) => axiosLoggedInInstance.patch(`/api/crud/workflows/${id}/`, params )
export const deleteWorkflow = (id: string) => axiosLoggedInInstance.delete(`/api/crud/workflows/${id}/`)
export const getWorkflow = (id: string): Promise<AxiosResourceResponse<Workflow>> => axiosLoggedInInstance.get(`/api/crud/workflows/${id}/`)
export const getWorkflowExecutions = (params: getWorkflowExecutionsRequestParams): Promise<AxiosListResponse<WorkflowExecution>> => axiosLoggedInInstance.get(`/api/crud/workflow_executions/`, { params })
export const getWorkflowExecutionsLoggedOut = (params: getWorkflowExecutionsRequestParams): Promise<AxiosListResponse<WorkflowExecution>> => axiosLoggedOutInstance.get(`/api/crud/no_auth_workflow_executions/`, { params })
export const getDashboards  = (params: getDashboardsRequest): Promise<AxiosListResponse<Dashboard>> => axiosLoggedInInstance.get(`/api/crud/dashboards/`, { params })
export const editDashboard = (id: string, params: dashboardPatchRequestParams) => axiosLoggedInInstance.patch(`/api/crud/dashboards/${id}/`, params )
export const deleteDashboard = (id: string) => axiosLoggedInInstance.delete(`/api/crud/dashboards/${id}/`)
export const getDashboard = (id: string): Promise<AxiosResourceResponse<Dashboard>> => axiosLoggedInInstance.get(`/api/crud/dashboards/${id}/`)
export const getStripePortal  = (account_id: string) => axiosLoggedInInstance.get(`/api/stripe/portal/`)
export const getStripeCheckout  = (cart_id: string) => axiosLoggedInInstance.get(`/api/stripe/checkout/`,  { params: {cart_id} })
export const getLemonSqueezyCheckout  = (cart_id: string) => axiosLoggedInInstance.get(`/api/lemonsqueezy/checkout/`,  { params: {cart_id} })
export const getAccountBillingMethod = (account_id: string): Promise<AxiosListResponse<AccountBillingMethod>>  => axiosLoggedInInstance.get(`/api/crud/account_billing_methods/`, { params: {ordering: "-created_date"}})
export const getAccountBios = (params: getAccountBiosRequest): Promise<AxiosListResponse<AccountBio>>  => axiosLoggedInInstance.get(`/api/crud/account_bios/`, { params })
export const getStoreItems = (params: getStoreItemsRequest): Promise<AxiosListResponse<StoreItem>>  => axiosLoggedInInstance.get(`/api/crud/store_items/`, { params })
export const getStoreCarts = (params: getStoreCartsRequestParams): Promise<AxiosListResponse<StoreCart>>  => axiosLoggedInInstance.get(`/api/crud/store_carts/`, { params})
export const getStoreCartItems = (cart_id: string): Promise<AxiosListResponse<StoreCartItem>>  => axiosLoggedInInstance.get(`/api/crud/store_cart_items/`, { params: { cart: cart_id }})
export const getAccountItemPurchases = (): Promise<AxiosListResponse<AccountItemPurchase>>  => axiosLoggedInInstance.get(`/api/crud/account_item_purchases/`)
export const deleteStoreCartItem = (id: string) => axiosLoggedInInstance.delete(`/api/crud/store_cart_items/${id}/`)
export const getFeatureFlags = (): Promise<AxiosListResponse<FeatureFlag>>  => axiosLoggedInInstance.get(`/api/crud/feature_flags/`)
export const getDataSetDashboards  = (params: getDataSetDashboardsRequest): Promise<AxiosListResponse<Dashboard>> => axiosLoggedInInstance.get(`/api/crud/data_set_dashboards/`, { params })
export const getPrompts  = (params: getPromptsRequest): Promise<AxiosListResponse<Prompt>> => axiosLoggedInInstance.get(`/api/crud/prompts/`, { params })
export const getPromptsLoggedOut  = (params: getPromptsRequest): Promise<AxiosListResponse<Prompt>> => axiosLoggedOutInstance.get(`/api/crud/no_auth_prompts/`, { params })
export const deletePrompt = (id: string) => axiosLoggedInInstance.delete(`/api/crud/prompts/${id}/`)
export const getTags  = (params: getTagsRequest): Promise<AxiosListResponse<Tag>> => axiosLoggedInInstance.get(`/api/crud/tags/`, { params })
export const getAiChats  = (params: getChatsRequest): Promise<AxiosListResponse<AiChat>> => axiosLoggedInInstance.get(`/api/crud/chats/`, { params })

export const getProjects  = (params: getProjectRequest): Promise<AxiosListResponse<Project>> => axiosLoggedInInstance.get(`/api/crud/projects/`, { params })
export const getProject = (id: string): Promise<AxiosResourceResponse<Project>> => axiosLoggedInInstance.get(`/api/crud/projects/${id}/`)
export const editProject = (id: string, params: projectPatchRequestParams) => axiosLoggedInInstance.patch(`/api/crud/projects/${id}/`, params )
export const deleteProject = (id: string) => axiosLoggedInInstance.delete(`/api/crud/projects/${id}/`)

export const getProjectSnippets  = (params: getProjectSnippetRequest): Promise<AxiosListResponse<ProjectSnippet>> => axiosLoggedInInstance.get(`/api/crud/project_snippets/`, { params })
export const getProjectSnippet = (id: string): Promise<AxiosResourceResponse<ProjectSnippet>> => axiosLoggedInInstance.get(`/api/crud/project_snippets/${id}/`)
export const editProjectSnippet = (id: string, params: projectSnippetPatchRequestParams) => axiosLoggedInInstance.patch(`/api/crud/project_snippets/${id}/`, params )
export const deleteProjectSnippet = (id: string) => axiosLoggedInInstance.delete(`/api/crud/project_snippets/${id}/`)


export const getNamedWorkflows  = (params: getNamedWorkflowsRequest): Promise<AxiosListResponse<NamedWorkflow>> => axiosLoggedInInstance.get(`/api/crud/named_workflows/`, { params })
export const getNamedWorkflowsLoggedOut  = (params: getNamedWorkflowsRequest): Promise<AxiosListResponse<NamedWorkflow>> => axiosLoggedOutInstance.get(`/api/crud/no_auth_named_workflows/`, { params })
export const getCreditTransactions  = (params: getCreditTransactionsRequest): Promise<AxiosListResponse<CreditTransaction>> => axiosLoggedInInstance.get(`/api/crud/credit_transactions/`, { params })
export const getPromptPacks  = (params: getPromptPacksRequest): Promise<AxiosListResponse<PromptPack>> => axiosLoggedInInstance.get(`/api/crud/prompt_packs/`, { params })

export const getInfoSets  = (params: getInfoSetsRequest): Promise<AxiosListResponse<InfoSet>> => axiosLoggedInInstance.get(`/api/crud/info_sets/`, { params })
