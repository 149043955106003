// import './theme.less'
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import {
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';
import './App.css';
import { useIsBigScreen, useIsSmallScreen, useIsXLargeScreen } from './app/hooks';
// import ReactGA from "react-ga";
import { ConfigProvider, Drawer, Layout, Menu, Space, Typography, theme, Row, Col } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import { FooterContent } from './components/FooterContent';
// import { AiChat } from './features/aiChat/AiChat';
import { Cart } from './features/cart/Cart';
import { Login } from './features/login/Login';
import { selectAccount, selectLoggedIn, selectLoginAttempted, selectStatus } from './features/login/loginSlice';
import { About } from './views/About/About';
import { Account } from './views/Account/Account';
import { Billing } from './views/Billing/Billing';
import { Bots } from './views/Bots/Bots';
import { Browse } from './views/Browse/Browse';
import { Contact } from './views/Contact/Contact';
import { Dashboard } from './views/Dashboard/Dashboard';
import { Dashboards } from './views/Dashboards/Dashboards';
import { DataProvider } from './views/DataProvider/DataProvider';
// import { DataProviding } from './views/DataProviding/DataProviding';
import { DataSets } from './views/DataSets/DataSets';

import { NotFoundPage } from './views/NotFoundPage/NotFoundPage';
import { DataSet } from './views/DataSet/DataSet';
import { PublicAccount } from './views/PublicAccount/PublicAccount';
import { Forecast } from './views/Forecast/Forecast';
// import { Home } from './views/Home/Home';
import { Home } from './views/Home/NewHome';
import { Newsletter } from './views/Newsletter/Newsletter';
import { OauthAuthorize } from './views/OauthAuthorize/OauthAuthorize';
import { PromoCodeRedeem } from './views/PromoCodeRedeem/PromoCodeRedeem';



import { Integrations } from './views/Integrations/Integrations';
import { Request } from './views/Request/Request';
import { Prompts } from './views/Prompts/Prompts';
import { Prompt } from './views/Prompt/Prompt';
import { Store } from './views/Store/Store';
import { Subscriptions } from './views/Subscriptions/Subscriptions';
import { Symbol } from './views/Symbol/Symbol';
import { Terms } from './views/Terms/Terms';
import { Workflow } from './views/Workflow/Workflow';
import { Workflows } from './views/Workflows/Workflows';
import { MyWorkflows } from './views/MyWorkflows/MyWorkflows';
import { Project as ProjectLegacy } from './views/Project/Project';
import { Project as ProjectV2 } from './views/Project/ProjectV2';
import { Projects } from './views/Projects/Projects';
import { WorkflowExecute } from './views/WorkflowExecute/WorkflowExecute';

import { Tag } from './views/Tag/Tag';
// import { LightDarkSwitch } from './features/lightDarkSwitch/LightDarkSwitch'
import { ApiStatus } from './components/ApiStatus';
import { GlobalSearch } from './components/GlobalSearch';
import { CreatePrompt } from './components/CreatePrompt';
import { NewsLetterBar } from './components/NewsLetterBar';

import { ConsentBanner } from './components/ConsentBanner';
import { LightDarkSwitch } from './features/lightDarkSwitch/LightDarkSwitch';
import { selectLight, selectTheme } from './features/lightDarkSwitch/lightDarkSwitchSlice';

import { useAppSelector } from './app/hooks';
import { getThemeConfig, getThemeBodyLight, getThemeHeaderLight, getThemeSideNavLight } from './themeRegistry';
import { content_pages } from './views/content/pages';
import { solutions_landing_pages } from './views/content/solutions_landing_pages';
import { squeeze_pages } from './views/content/squeeze_pages';
import { guide_pages } from './views/content/prompt_guide_2023';

import { workflow_landing_pages } from './views/content/workflow_landing_pages';
// import { ReactComponent as PyroPromptsLogo } from './images/freqsignals_logo.svg'
import Icon, {
  ApiOutlined,
  BranchesOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  EllipsisOutlined,
  FileTextOutlined, FolderOpenOutlined,
  HomeOutlined,
  LineChartOutlined,
  SendOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  SubnodeOutlined,
  TeamOutlined,
  BarsOutlined,
  BlockOutlined,
} from '@ant-design/icons';
import { default as PyroPromptsLogo } from './images/pyroprompts';

const { Header, Sider, Content, Footer } = Layout;

interface MenuSelection {
  key: string
}

export type LayoutType = 'Sidebar' | 'Topnav'

interface Layout {
  type: LayoutType
}

const layout_type: LayoutType = 'Topnav'
const layout: Layout = {type: layout_type}
const layout_type_class_names = {
  Sidebar: 'sidebar',
  Topnav: 'topnav'
}

function SideNav() {
  let navigate = useNavigate();
  const light = useAppSelector(selectLight)

  let location = useLocation();

  const items = [
    { label: 'Home', key: '/', icon: <HomeOutlined /> },
    { label: 'Dashboards', key: '/dashboards', icon: <DashboardOutlined /> },
    { label: 'Browse', key: '/browse', icon: <FolderOpenOutlined /> },
    { label: 'Forecast', key: '/forecast', icon: <LineChartOutlined /> },
    { label: 'Subscriptions', key: '/subscriptions', icon: <SubnodeOutlined /> },
    // { label: 'Request', key: '/request', icon: <PullRequestOutlined /> },
    { label: 'Data Providing', key: '/data_providing', icon: <DatabaseOutlined /> },
    { label: 'Billing', key: '/billing', icon: <CreditCardOutlined /> },
    { label: 'About', key: '/about', icon: <TeamOutlined /> },
    { label: 'Contact', key: '/contact', icon: <SendOutlined /> },
    { label: 'Terms / Privacy', key: '/terms', icon: <FileTextOutlined /> },
  ];

  const onSelect = ({key}: MenuSelection) => {
    navigate(key)
  }
  // console.log("location", {location})

  return <Menu items={items} theme={light ? 'light' : 'dark'} onSelect={onSelect} selectedKeys={[location.pathname]} />

}

function TopNav() {
  let navigate = useNavigate();
  let location = useLocation();
  const account = useAppSelector(selectAccount);
  const light = useAppSelector(selectLight);
  const selectedTheme = useAppSelector(selectTheme);
  const isSmallScreen = useIsSmallScreen()
  const isBigScreen = useIsBigScreen()
  const isXLargeScreen = useIsXLargeScreen()
  const [navOpen, setNavOpen] = useState<boolean>(false)
  const isTopNav = isBigScreen

  const filtersByLight = {
    'light': 'invert(20%) sepia(0%) saturate(1000%) hue-rotate(205deg) brightness(97%) contrast(94%)',
    'dark': 'invert(93%) sepia(0%) saturate(0%) hue-rotate(205deg) brightness(97%) contrast(94%)',
  }

  const topNavFilterColor = filtersByLight[getThemeHeaderLight(selectedTheme)]
  const sideNavFilterColor = filtersByLight[getThemeSideNavLight(selectedTheme)]

  const onNavSelect = ({key}: MenuSelection) => {
    if (key.startsWith('drawer')) {
      setNavOpen(true)
    } else if (key === '/') {
      navigate(key)
    }
  }

  const topNavMenuItems = [
    {
      // label: (
      //   <PyroPromptsLogo style={{
      //     // filter here https://codepen.io/sosuke/pen/Pjoqqp
      //     // filter: 'invert(37%) sepia(97%) saturate(670%) hue-rotate(355deg) brightness(86%) contrast(86%)',
      //     filter: topNavFilterColor,
      //     marginTop: isTopNav ? '2px' : '6px',
      //     height: '50px',
      //     width: isTopNav ? '200px' : '140px',
      //   }}/>
      // ),
      label: <Space>
        <img style={{width: "28px"}} src={"/img/pyroprompts_icon.png"} alt={"PyroPrompts Flame Logo"} title={"PyroPrompts Flame"} />
        <h1 style={{"marginTop": 0}}>PyroPrompts</h1>
      </Space>,
      // icon: <img style={{width: "32px", marginTop: '12px'}} src={"/img/pyroprompts_icon.png"} alt={"PyroPrompts Flame Logo"} title={"PyroPrompts Flame"} />,
      key: isTopNav ? '/' : 'drawerlogo',
    },
    ...(isTopNav ? [
      { key: '/prompts', icon: <BarsOutlined />, label: <Link to='/prompts'>Prompt Library</Link> },
      // { label: 'Signal Providers', key: '/data_providing', icon: <DatabaseOutlined /> },
      // { label: 'Signals', key: '/forecast', icon: <LineChartOutlined /> },
      ...(account ? [{ key: '/projects', icon: <BlockOutlined />, label: <Link to='/projects'>Projects</Link> }] : []),
      // ...(account ? [{ key: '/projects', icon: <BlockOutlined />, label: 'Projects' }] : []),
      { key: '/workflows', icon: <BranchesOutlined />, label: <Link to='/workflows'>Workflows</Link> },
      { key: '/data_sets', icon: <DatabaseOutlined />, label: <Link to='/data_sets'>Data Sets</Link> },
      // { key: '/dashboards', icon: <DashboardOutlined />, label: 'Dashboards' },
    ]: [
      { key: 'drawer', icon: <EllipsisOutlined /> },
    ])

    // { label: 'PyroPrompts', key: '/', icon: <HomeOutlined /> },


    // { key: '/pricing', label: 'Subscriptions' },

    // { label: 'Integrations', key: '/integrations', icon: <ApiOutlined /> },
    // { label: 'Bots', key: '/bots', icon: <RobotOutlined /> },
    // { label: 'Pricing', key: '/pricing', icon: <DollarOutlined /> },
    // { label: 'Pro', key: '/about', icon: <TeamOutlined /> },
    // { label: 'About', key: '/about', icon: <TeamOutlined /> },
  ];

  const drawerMenuItems = [
    { key: '/', icon: <HomeOutlined />, label: 'Home' },
    // { label: 'PyroPrompts', key: '/', icon: <HomeOutlined /> },
    { key: '/prompts', icon: <BarsOutlined />, label: 'Prompt Library' },
    // { label: 'Signal Providers', key: '/data_providing', icon: <DatabaseOutlined /> },
    // { label: 'Signals', key: '/forecast', icon: <LineChartOutlined /> },
    ...(account ? [{ key: '/projects', icon: <BlockOutlined />, label: 'Projects' }] : []),
    { key: '/workflows', icon: <BranchesOutlined />, label: 'Workflows' },
    { key: '/data_sets', icon: <DatabaseOutlined />, label: 'Data Sets' },
    // { key: '/dashboards', icon: <DashboardOutlined />, label: 'Dashboards' },
    { key: '/store', icon: <ShoppingCartOutlined />, label: 'Store' },
    // { key: '/integrations', icon: <ApiOutlined />, label: 'Integrations' },
    { key: '/account', icon: <SettingOutlined />, label: 'Account', disabled: !account },
    // { key: '/pricing', label: 'Subscriptions' },

  ];

  const onSelect = ({key}: MenuSelection) => {
    setNavOpen(false)
    navigate(key)
  }
  // console.log("location", {location})

  return (
    <ConfigProvider
      theme={{
        token: {
          "borderRadius": 0
        }
      }}
    >
      <Menu items={topNavMenuItems} theme={getThemeHeaderLight(selectedTheme)} mode="horizontal" onSelect={onNavSelect} selectedKeys={[location.pathname]} className={'topnav'} />

      {/* <Menu items={items} theme={light ? 'light' : 'dark'} mode="horizontal" onSelect={onSelect} selectedKeys={[location.pathname]} /> */}
      {!isTopNav && (
        <ConfigProvider
          theme={{
            token: {
              "borderRadius": 4
            }
          }}
        >
        <Drawer
            title={
              <h1>PyroPrompts</h1>
              // <PyroPromptsLogo style={{
              //   filter: sideNavFilterColor,
              //   marginTop: '6px',
              //   height: '50px',
              //   width:'140px',
              //   }}
              // />
            }
            placement={'left'}
            closable={false}
            className={`base-theme-${light ? 'light' : 'dark'} theme-${selectedTheme} drawer-nav`}
            width={'80%'}
            onClose={() => setNavOpen(false)}
            open={navOpen}
            // extra={
            //   <Space>
            //       <ApiStatus />
            //       <GlobalSearch includeTitle={false} onSelect={() => setNavOpen(false)}/>
            //       <CreatePrompt buttonType='text' buttonText="Prompt" />
            //   </Space>
            // }
            footer={
              <>
                <Row>
                  {[
                    ...(account ? [<GlobalSearch includeTitle={true} onSelect={() => setNavOpen(false)}/>] : []),
                    <CreatePrompt buttonType='text' buttonText="Prompt" />,
                    <LightDarkSwitch mode='text-button' />,
                    <Cart defaultText="Cart"/>,
                  ].map((elem, i) => (
                    <Col span={12} key={i}>
                      {elem}
                    </Col>
                  ))}
                </Row>
              </>
            }
          >
            <Menu items={drawerMenuItems} theme={getThemeSideNavLight(selectedTheme)} mode="vertical" onSelect={onSelect} selectedKeys={[location.pathname]} className={'drawer-nav-menu'} />
          </Drawer>
        </ConfigProvider>
      )}
    </ConfigProvider>
  )
}

function RightNav() {
  const isBigScreen = useIsBigScreen()
  const account = useAppSelector(selectAccount);


  return <div style={{float: 'right'}}>
    <Space>
      {/* <ApiStatus /> */}
      {/* {isBigScreen && (
        <ApiStatus />
      )} */}
      {account && isBigScreen && (
        <GlobalSearch includeTitle={true} />
      )}
      {/* {isBigScreen && (
        <CreatePrompt buttonType="text" />
      )} */}
      {/* {isBigScreen && (
        <AiChat />
      )} */}
      {isBigScreen && (
        <Cart />
      )}
      <Login />
    </Space>

  </div>
}

function HeroLogo() {
  let navigate = useNavigate();

  return (
    <div className="logo" style={{margin: '12px', cursor: 'pointer'}} onClick={() => navigate('/')}>
      <h2>PyroPrompts</h2>
      Subscribable Data
    </div>
  )
}

function TopNavHeroLogo() {
  let navigate = useNavigate();

  return (
    <div className="logo" style={{margin: '12px', cursor: 'pointer'}} onClick={() => navigate('/')}>
      <Icon
          component={PyroPromptsLogo}
          // className={classnames('mr-1', className)}
          style={{ fontSize: '8rem', filter: 'invert(37%) sepia(97%) saturate(670%) hue-rotate(355deg) brightness(86%) contrast(86%)', height: '24px', width: '200px' }}
      />
    </div>
  )
}

function AppHeader() {
  return (
    <div className='app-header'>
      <RightNav/>
      <TopNav/>
    </div>
  )
}

function PageTracker() {
  const location = useLocation();
  const account = useAppSelector(selectAccount);

  useEffect(() => {
    // console.log("location", {location})
    // ReactGA.pageview(location.pathname + location.search);
    ReactGA.send({hitType: "pageview", page: location.pathname + location.search});
  }, [location]);

  useEffect(() => {
    // console.log("location", {location})
    // ReactGA.pageview(location.pathname + location.search);
    if (account) {
      ReactGA.set({account_name: account.name, account_id: account.id});
    }
  }, [account?.id]);
  return <></>
}

const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return <></>
};

const getRoutes = () => {
  // console.log("getRoutes")
  const routes = (
    <Routes>
      <Route path="" element={<Home />}/>
      <Route path="/" element={<Home />}/>
      <Route path="/about" element={<About />}/>
      <Route path="/browse" element={<Browse />}/>
      <Route path="/subscriptions" element={<Subscriptions />}/>
      <Route path="/prompts" element={<Prompts />}/>
      {/* <Route path="/data_providing" element={<DataProviding />}/> */}
      <Route path="/forecast" element={<Forecast />}/>
      <Route path="/request" element={<Request />}/>
      <Route path="/billing" element={<Billing />}/>
      <Route path="/contact" element={<Contact />}/>
      <Route path="/terms" element={<Terms />}/>
      <Route path="/my_workflows" element={<MyWorkflows />}/>
      <Route path="/workflows" element={<Workflows />}/>
      <Route path="/workflows">
        <Route path=":id" element={<Workflow />} />
      </Route>
      <Route path="/projects" element={<Projects />}/>
      <Route path="/projects">
        <Route path=":id" element={<ProjectV2 />} />
      </Route>
      <Route path="/projects_new">
        <Route path=":id" element={<ProjectV2 />} />
      </Route>
      <Route path="/projects_legacy">
        <Route path=":id" element={<ProjectLegacy />} />
      </Route>
      
      <Route path="/integrations" element={<Integrations />}/>
      <Route path="/pricing" element={<Store />}/>
      <Route path="/upgrade" element={<Store />}/>
      <Route path="/store" element={<Store />}/>
      <Route path="/bots" element={<Bots />}/>
      <Route path="/dashboards" element={<Dashboards />}/>
      <Route path="/dashboards">
        <Route path=":id" element={<Dashboard />} />
      </Route>
      <Route path="/data_sets" element={<DataSets />}/>
      <Route path="/data_sets">
        <Route path=":id" element={<DataSet />} />
      </Route>
      <Route path="/u">
        <Route path=":username" element={<PublicAccount />} />
      </Route>
      <Route path="/w">
        <Route path=":name" element={<WorkflowExecute />} />
      </Route>
      <Route path="/tags/:tag_category/:tag_name" element={<Tag />} />
      <Route path="/prompt">
        <Route path=":id" element={<Prompt />} />
      </Route>
      <Route path="/data_providers">
        <Route path=":id" element={<DataProvider />} />
      </Route>
      <Route path="/symbols">
        <Route path=":id" element={<Symbol />} />
      </Route>
      <Route path="/profile" element={<Profile />}/>
      <Route path="/account" element={<Account />}/>
      {/* <Route path="/discord_bot" element={<DiscordBotContent />}/> */}
      {/* <Route path="/twitter_bot" element={<TwitterBotContent />}/> */}
      {/* <Route path="/telegram_bot" element={<TelegramBotContent />}/> */}
      <Route path="/workflow_automation" element={<content_pages.workflow_automation />}/>
      <Route path="/ai_monitoring" element={<content_pages.ai_monitoring />}/>
      <Route path="/newsletter" element={<Newsletter />}/>
      {/* <Route path="/freqtrade_crypto_bot" element={<FreqtradeCryptoBotContent />}/> */}
      {/* <Route path="/trading_view_webhook" element={<TradingViewWebhookContent />}/> */}
      {/* <Route path="/on_demand_analysis" element={<OnDemandAnalysisContent />}/> */}
      {/* <Route path="/analysis_tracking" element={<AnalysisTrackingContent />}/> */}
      <Route path="/consulting" element={<content_pages.consulting />}/>
      {/* <Route path="/affiliate" element={<AffiliateContent />}/> */}
      <Route path="/advertising" element={<content_pages.advertising />}/>
      {/* <Route path="/data_providing_1p_3p" element={<DataProvidingContent />}/> */}
      <Route path="/company" element={<content_pages.company />}/>
      {/* <Route path="/careers" element={<CareersContent />}/> */}
      {/* <Route path="/investors" element={<InvestorsContent />}/> */}
      <Route path="/terms_and_conditions" element={<content_pages.terms_conditions />}/>
      <Route path="/privacy_policy" element={<content_pages.privacy />}/>
      <Route path="/custom_gpts" element={<content_pages.custom_gpts />}/>
      
      {/* <Route path="/integration_offerings" element={<IntegrationOfferings />}/> */}
      {/* <Route path="/data_provider_terms_and_conditions" element={<DataProviderTermsConditionsContent />}/> */}
      {/* <Route path="/subscriber_terms_and_conditions" element={<SubscriberTermsConditionsContent />}/> */}
      <Route path="/community" element={<content_pages.community />}/>
      <Route path="/contact_us" element={<content_pages.contact_us />}/>
      <Route path="/enterprise" element={<squeeze_pages.enterprise />}/>
      <Route path="/documentation" element={<content_pages.documentation />}/>
      <Route path="/updates" element={<content_pages.updates />}/>
      <Route path="/workflow_scheduling" element={<squeeze_pages.workflow_scheduling />}/>
      <Route path="/workflow_event_handling" element={<squeeze_pages.workflow_event_handling />}/>
      <Route path="/workflow_hosting" element={<squeeze_pages.workflow_hosting />}/>
      <Route path="/extension" element={<squeeze_pages.extension />}/>
      <Route path="/new_year_no_code_ai_marketing_masters" element={<squeeze_pages.new_year_no_code_ai_marketing_masters />}/>
      <Route path="/market_analysis_prompt_pack" element={<squeeze_pages.market_analysis_prompt_pack />}/>
      <Route path="/ai_for_digital_marketers" element={<squeeze_pages.ai_for_digital_marketers />}/>
      <Route path="/ai_for_best_summer_ever" element={<squeeze_pages.ai_for_best_summer_ever />}/>
      
      {/* <Route path="/use_data_with_rag" element={<squeeze_pages.use_data_with_rag />}/> */}
      
      

      <Route path="/prompt_guide_2023" element={<guide_pages.prompt_guide_2023 />}/>
      <Route path="/prompt_guide_2023/history_of_ai_prompting_and_language_models" element={<guide_pages.history_of_ai_prompting_and_language_models />}/>
      <Route path="/prompt_guide_2023/understanding_prompting" element={<guide_pages.understanding_prompting />}/>
      <Route path="/prompt_guide_2023/memory_of_language_models" element={<guide_pages.memory_of_language_models />}/>
      <Route path="/prompt_guide_2023/examples_zero_shot_one_shot_multi_shot" element={<guide_pages.examples_zero_shot_one_shot_multi_shot />}/>
      <Route path="/prompt_guide_2023/crafting_effective_prompts" element={<guide_pages.crafting_effective_prompts />}/>
      <Route path="/prompt_guide_2023/output_format_specification" element={<guide_pages.output_format_specification />}/>
      <Route path="/prompt_guide_2023/tone_and_style" element={<guide_pages.tone_and_style />}/>
      <Route path="/prompt_guide_2023/dealing_with_potential_bias" element={<guide_pages.dealing_with_potential_bias />}/>
      <Route path="/prompt_guide_2023/parameter_setting_for_prompts" element={<guide_pages.parameter_setting_for_prompts />}/>
      <Route path="/prompt_guide_2023/troubleshooting_common_prompting_issues" element={<guide_pages.troubleshooting_common_prompting_issues />}/>
      <Route path="/prompt_guide_2023/ethics_and_prompting" element={<guide_pages.ethics_and_prompting />}/>
      <Route path="/prompt_guide_2023/advanced_prompting_techniques" element={<guide_pages.advanced_prompting_techniques />}/>
      <Route path="/prompt_guide_2023/prompting_in_different_contexts" element={<guide_pages.prompting_in_different_contexts />}/>
      <Route path="/prompt_guide_2023/hallucinations" element={<guide_pages.hallucinations />}/>
      <Route path="/prompt_guide_2023/future_of_prompting" element={<guide_pages.future_of_prompting />}/>
      <Route path="/prompt_guide_2023/exploring_real_world_applications" element={<guide_pages.exploring_real_world_applications />}/>
      <Route path="/oauth2_authorize" element={<OauthAuthorize />}/>
      <Route path="/promo_code_redeem" element={<PromoCodeRedeem />}/>

      {/* <Route path="/ai_solutions_for_content_creators" element={<solutions_landing_pages.content_creators />}/> */}
      {/* <Route path="/ai_solutions_for_teachers" element={<solutions_landing_pages.teachers />}/> */}
      {/* <Route path="/ai_solutions_for_project_managers" element={<solutions_landing_pages.project_managers />}/> */}
      {/* <Route path="/ai_solutions_for_web_developers" element={<solutions_landing_pages.web_developers />}/> */}
      {/* <Route path="/ai_solutions_for_machine_learning_engineers" element={<solutions_landing_pages.machine_learning_engineers />}/> */}
      {/* <Route path="/ai_solutions_for_product_designers" element={<solutions_landing_pages.product_designers />}/> */}
      {/* <Route path="/ai_solutions_for_marketers" element={<solutions_landing_pages.marketers />}/> */}
      {/* <Route path="/ai_solutions_for_sales" element={<solutions_landing_pages.sales />}/> */}
      {/* <Route path="/ai_solutions_for_lawyers" element={<solutions_landing_pages.lawyers />}/> */}
      {/* <Route path="/workflow-project-suggestion" element={<workflow_landing_pages.project_suggestion />}/> */}
      <Route path="/workflow-focus-group" element={<workflow_landing_pages.focus_group />}/>
      <Route path="*" element={<NotFoundPage />}/>
    </Routes>
  )
  return routes
}

function App() {
  const light = useAppSelector(selectLight)
  const selectedTheme = useAppSelector(selectTheme)
  // const status = useAppSelector(selectStatus);
  const isBigScreen = useIsBigScreen()
  const isSmallScreen = useIsSmallScreen()

  const loggedIn = useAppSelector(selectLoggedIn);
  // const loginAttempted = useAppSelector(selectLoginAttempted);



  const fontConfig = {
    "fontFamily": "'Noto Sans', proxima-nova, sans-serif"
  }

  const sizeSpaceConfig = {
    "borderRadius": 4
  }

  const commonColorConfig = {
    "colorPrimary": "#1765ad",
  }


  const configProviderConfig = {
    algorithm: light ? theme.defaultAlgorithm : theme.darkAlgorithm,
    token: {
      ...sizeSpaceConfig,
      ...commonColorConfig,
      ...fontConfig,
      ...getThemeConfig(selectedTheme),
    }
  }

  // const allowedNoLoadingPage = ['']
  const content = () => {
    return <>
      <NewsLetterBar />
      {getRoutes()}
    </>

    // console.log("location", {location})
    // if ( (status === 'loading' || !loginAttempted)) {
    //   return (
    //     <div className="App">
    //       <div className="App-content">
    //         <div className="App-page-content" style={{textAlign: 'center'}}>
    //             Loading...
    //         </div>
    //       </div>
    //     </div>
    //   ) 
    // } else {
    //   return getRoutes()
    // }

  }

  if (layout.type === 'Topnav') {
    return (

        <Router>
          <PageTracker />
          <ScrollToTop />
          <ConfigProvider
            theme={configProviderConfig}
          >
            <Layout className={`layout-${layout_type_class_names[layout.type]} base-theme-${light ? 'light' : 'dark'} theme-${selectedTheme} screen-${isSmallScreen ? 'small' : 'big'}`}>
              <Header>
                <AppHeader />
              </Header>
              <Content>
                {content()}
              </Content>
              <Footer>
                <FooterContent />
              </Footer>
              <ConsentBanner />
            </Layout>
          </ConfigProvider>
        </Router>
    );
  }

  return (
    <Router>
      <PageTracker />
      <ScrollToTop />
      <ConfigProvider
        theme={configProviderConfig}
      >
      <Layout className={`layout-${layout_type_class_names[layout.type]} base-theme-${light ? 'light' : 'dark'} theme-${selectedTheme}`}>
        <Sider>
          <HeroLogo />
          <SideNav/>
        </Sider>
        <Layout>
          <Header>
            <RightNav/>
          </Header>
          <Content>
            {content()}
          </Content>
          {/* <Footer>Footer</Footer> */}
        </Layout>
      </Layout>
      </ConfigProvider>
    </Router>
  
  );

}

// function Home() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         {/* <img src={logo} className="App-logo" alt="logo" /> */}
//         <p>
//           Home <code>src/App.tsx</code> and save to reload.
//           cool: {process.env.REACT_APP_NOT_SECRET_CODE}
//         </p>
//         <Counter/>
//       </header>
//     </div>
//   );
// }

function Profile() {
  return (
    <div className="App">
      <header className="App-header">
        <Typography.Paragraph>
          profile <code>src/App.tsx</code> and save to reload.
          cool: {process.env.REACT_APP_NOT_SECRET_CODE}
        </Typography.Paragraph>
      </header>
    </div>
  );
}

export default App;
