import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
// import { authenticate_username } from './loginAPI';
import { confirmRegisterUser, getAccountsLoggedOut, getAccounts, getAuthToken, refreshToken, registerUser, requestResetPassword, submitResetPassword, getFeatureFlagsLoggedOut } from '../../services/signalApi';
import { Account, MyAccount, FeatureFlag } from '../../types/types';
import ReactGA from "react-ga4";


type Level = 'success' | 'info' | 'error' | 'warning'


export interface LoginState {
  logged_in: boolean
  login_username?: string
  status: 'idle' | 'loading' | 'failed'
  access_token?: string
  refresh_token?: string
  scope?: string
  expires_at?: Date
  account?: MyAccount
  accounts?: MyAccount[]
  feature_flags?: FeatureFlag[]
  login_attempted: boolean
  login_message?: string
  message?: string
  message_level?: Level
}

export interface UserLoginData {
  username: string
  password: string
  remember_me?: boolean
}

export interface UserRegisterData {
  username: string
  email: string
  password: string
  newsletter: '1' | '0'
}

export interface UserRegisterConfirmData {
  username: string
  code: string
  workflow_execution_id?: string
}

export interface ResetPasswordData {
  username: string
}

export interface SubmitResetPasswordData {
  username: string
  code: string
  password: string
}

const initialState: LoginState = {
  logged_in: false,
  status: 'idle',
  login_attempted: false,
};

const sleep = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const authenticateAsync = createAsyncThunk(
  'login/authenticate',
  async (userData: UserLoginData) => {
    // await sleep(2000)
    const response = await getAuthToken(userData.username.toLowerCase(), userData.password);
    const accounts_response = await getAccountsLoggedOut(response.data.access_token);
    const features_response = await getFeatureFlagsLoggedOut(response.data.access_token)
    // The value we return becomes the `fulfilled` action payload
    console.log("accounts_response", {accounts_response, features_response})
    ReactGA.event('login', {method: 'native'});

    // console.log(response)
    // console.log(response.data)
    // console.log(response.data.access_token)


    return {
      username: userData.username,
      ...response.data,
      accounts: accounts_response.data.results,
      remember_me: userData.remember_me,
      features: features_response.data.results
    }
    
  }
);

export const registerAsync = createAsyncThunk(
  'login/register',
  async (userData: UserRegisterData) => {
    const response = await registerUser(userData.username, userData.email.toLowerCase(), userData.password, userData.newsletter);
    // The value we return becomes the `fulfilled` action payload
    // console.log(response)
    // console.log(response.data)
    // console.log(response.data.access_token)
    if (response.status !== 200) {
      ReactGA.event('sign_up_failure', {method: 'native'});
      return response.data
    }
    ReactGA.event('sign_up', {method: 'native'});

    return {
      username: userData.username,
      ...response.data
    }
  }
);

export const registerConfirmAsync = createAsyncThunk(
  'login/register_confirm',
  async (userData: UserRegisterConfirmData) => {
    const response = await confirmRegisterUser(userData.username, userData.code, userData.workflow_execution_id);
    // The value we return becomes the `fulfilled` action payload
    // console.log(response)
    // console.log(response.data)
    // console.log(response.data.access_token)
    if (response.status !== 200) {
      return response.data
    }
    return {
      username: userData.username,
      ...response.data
    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  'login/reset_password',
  async (userData: ResetPasswordData) => {
    const response = await requestResetPassword(userData.username);
    // The value we return becomes the `fulfilled` action payload
    // console.log(response)
    // console.log(response.data)
    // console.log(response.data.access_token)
    if (response.status !== 200) {
      return response.data
    }
    return {
      username: userData.username,
      ...response.data
    }
  }
);

export const submitResetPasswordAsync = createAsyncThunk(
  'login/submit_reset_password',
  async (userData: SubmitResetPasswordData) => {
    const response = await submitResetPassword(userData.username, userData.code, userData.password);
    // The value we return becomes the `fulfilled` action payload
    // console.log(response)
    // console.log(response.data)
    // console.log(response.data.access_token)
    if (response.status !== 200) {
      return response.data
    }
    return {
      username: userData.username,
      ...response.data
    }
  }
);


export const attemptAuthFromRefreshToken = createAsyncThunk(
  'login/attempt_authentication_from_refresh_token',
  async () => {
    console.log("attemptAuthFromRefreshToken")
    const refresh_token = localStorage.getItem("REFRESH_TOKEN")
    const username = localStorage.getItem("LOGIN_USERNAME")
    console.log("refresh_token", {username, refresh_token})

    if (refresh_token) {
      console.log("attempting reauth")
      const new_token_info = await refreshToken(refresh_token)
      console.log("attempting get_accounts new_token_info", {new_token_info})
      const { refresh_token: new_refresh_token, access_token } = new_token_info.data
      console.log("attempting get_accounts")

      const accounts_response = await getAccountsLoggedOut(access_token);
      const features_response = await getFeatureFlagsLoggedOut(access_token)
      if (accounts_response.status !== 200) {
        // not logged in, requires re-auth
        console.log("not logged in", {accounts_response})
      }
      console.log("reauthed", {new_refresh_token, access_token, username, accounts_response})

      return {
        refresh_token: new_refresh_token,
        access_token,
        username,
        accounts: accounts_response.data.results,
        features: features_response.data.results
      }
    }
  }
);

export const reloadAccount = createAsyncThunk(
  'login/reload_account',
  async () => {
    console.log("reloadAccount")

    const accounts_response = await getAccounts();
    if (accounts_response.status !== 200) {
      // not logged in, requires re-auth
      console.log("not logged in", {accounts_response})
    }

    return {
      accounts: accounts_response.data.results || [],
    }
  }
);

export const attemptAuthFromTokens = createAsyncThunk(
  'login/attempt_authentication_from_tokens',
  async (_, thunkApi) => {
    // console.log("attemptAuthFromTokens")
    const access_token = localStorage.getItem("ACCESS_TOKEN")
    const username = localStorage.getItem("LOGIN_USERNAME")
    const remember_me = localStorage.getItem("REMEMBER_ME")
    // console.log("access_token", {username, access_token})

    if (access_token) {
      // await sleep(2000)
      const accounts_response = await getAccountsLoggedOut(access_token);
      const features_response = await getFeatureFlagsLoggedOut(access_token)
      if (accounts_response.status !== 200) {
        // not logged in, requires re-auth
        console.log("not logged in", {accounts_response})
        if (remember_me) {
          thunkApi.dispatch(attemptAuthFromRefreshToken())
        }
      }
      return {
        access_token,
        username,
        accounts: accounts_response.data.results,
        features: features_response.data.results
      }
    } else if (remember_me) {
      thunkApi.dispatch(attemptAuthFromRefreshToken())
    }
  }
);


export const loginSlice = createSlice({
  name: 'login',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    authenticate: (state, action: PayloadAction<string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // console.log("authenticate", {action})

      state.logged_in = true
      state.login_username = action.payload
    },
    log_out: (state) => {
        console.log("log_out")
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.logged_in = false
        delete state.login_username
        delete state.access_token
        delete state.refresh_token
        delete state.expires_at
        delete state.scope
        localStorage.removeItem("ACCESS_TOKEN")
        localStorage.removeItem("REFRESH_TOKEN")
        localStorage.removeItem("LOGIN_USERNAME")

        const old_search = window.location.search
        const new_search = window.location.search.replace("&auth=login", "").replace("?auth=login", "")
        console.log("new_search in auth", {new_search, old_search: old_search, location: window.location})
        if (old_search === new_search) {
          window.location.reload();
        } else {
          window.location.search = new_search
        }

    },
    clear_message: (state) => {
      // delete state.login_message
      delete state.message
      delete state.message_level
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(authenticateAsync.pending, (state) => {
        state.status = 'loading';
        console.log("1")
      })
      .addCase(authenticateAsync.fulfilled, (state, action) => {
        console.log("2")
        state.status = 'idle';
        state.logged_in = true
        state.login_username = action.payload.username
        state.access_token = action.payload.access_token
        state.refresh_token = action.payload.refresh_token
        state.scope = action.payload.scope
        const accounts = action.payload.accounts
        if (accounts) {
          state.account = accounts[0]
          state.accounts = accounts
        }
        const features = action.payload.features
        if (features) {
          state.feature_flags = features
          // console.log("features", {features})
        }
        // console.log("accounts", {accounts})
        localStorage.setItem("ACCESS_TOKEN", action.payload.access_token)
        localStorage.removeItem("REAUTH_ATTEMPTS")
        localStorage.setItem("REFRESH_TOKEN", action.payload.refresh_token)
        localStorage.setItem("LOGIN_USERNAME", action.payload.username.toLowerCase())
        localStorage.setItem("REMEMBER_ME", action.payload.remember_me)
        const old_search = window.location.search
        const new_search = window.location.search.replace("&auth=login", "").replace("?auth=login", "")
        console.log("new_search in auth", {new_search, old_search: old_search, location: window.location})
        if (old_search === new_search) {
          window.location.reload();
        } else {
          window.location.search = new_search
        }
      })
      .addCase(authenticateAsync.rejected, (state) => {
        state.status = 'failed';
        console.log("3")
        state.message = "Login Failed"
        state.message_level = "error"
      })
      //
      .addCase(attemptAuthFromTokens.pending, (state) => {
        state.status = 'loading';
        state.login_attempted = true;
      })
      .addCase(attemptAuthFromTokens.fulfilled, (state, action) => {
        state.status = 'idle';
        // console.log('attemptAuthFromTokens.fultilled', {state, action})
        state.login_attempted = true;
        if (action.payload?.username && action.payload?.access_token && action.payload?.accounts){
          state.logged_in = true
          state.login_username = action.payload.username
          state.access_token = action.payload.access_token
          const accounts = action.payload.accounts
          if (accounts) {
            state.account = accounts[0]
            state.accounts = accounts
            // console.log("accounts", {accounts})
          }
          const features = action.payload.features
          if (features) {
            state.feature_flags = features
            // console.log("features", {features})
          }
        } else{
          // state.logged_in = false

          localStorage.removeItem("ACCESS_TOKEN")
        }
      })
      .addCase(attemptAuthFromTokens.rejected, (state) => {
        state.status = 'failed';
        state.login_attempted = true;
      })
      //
      .addCase(attemptAuthFromRefreshToken.pending, (state) => {
        state.status = 'loading';
        state.login_attempted = true;
      })
      .addCase(attemptAuthFromRefreshToken.fulfilled, (state, action) => {
        console.log("attemptAuthFromRefreshToken.fulfilled", {action})
        state.status = 'idle';
        state.login_attempted = true;
        if (action.payload?.username && action.payload?.access_token && action.payload?.accounts && action.payload?.refresh_token){
          console.log("attemptAuthFromRefreshToken.has_needed", {action})
          state.logged_in = true
          state.login_username = action.payload.username
          state.access_token = action.payload.access_token
          state.refresh_token = action.payload.refresh_token
          const accounts = action.payload.accounts
          if (accounts) {
            state.account = accounts[0]
            state.accounts = accounts
            console.log("accounts", {accounts})
          }
          const features = action.payload.features
          if (features) {
            state.feature_flags = features
            console.log("features", {features})
          }
          localStorage.setItem("REFRESH_TOKEN", action.payload.refresh_token)
          localStorage.setItem("ACCESS_TOKEN", action.payload.access_token)
          localStorage.removeItem("REAUTH_ATTEMPTS")
          localStorage.setItem("LOGIN_USERNAME", action.payload.username.toLowerCase())

        } else{
          // state.logged_in = false
          console.log("attemptAuthFromRefreshToken.missing_needed", {action})

          localStorage.removeItem("ACCESS_TOKEN")
        }
      })
      .addCase(attemptAuthFromRefreshToken.rejected, (state) => {
        state.status = 'failed';
        state.login_attempted = true;
      })
      //
      .addCase(reloadAccount.fulfilled, (state, action) => {
        console.log("reloadAccount.fulfilled", {action})
        if (action.payload?.accounts){
          console.log("reloadAccount.has_needed", {action})
          state.logged_in = true
          const accounts = action.payload.accounts
          if (accounts) {
            state.account = accounts[0]
            state.accounts = accounts
            console.log("accounts", {accounts})
          }
        } else{
          // state.logged_in = false
          console.log("attemptAuthFromRefreshToken.missing_needed", {action})

          localStorage.removeItem("ACCESS_TOKEN")
        }
      })
      .addCase(reloadAccount.rejected, (state) => {
        state.status = 'failed';
        state.login_attempted = true;
      })
      //
      .addCase(registerAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registerAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log("registerAsync", {action})
        if (action.payload === "FullForDay"){
          // state.login_message = "We are not accepting new sign-ups right now. Please try again later."
          state.message = "We are not accepting new sign-ups right now. Please try again later."
          state.message_level = "error"
        } else if (action.payload === "EmailError"){
          // state.login_message = "Something is wrong with your email. Please check it and try again."
          state.message = "Something is wrong with your email. Please check it and try again."
          state.message_level = "error"
        } else if (action.payload === "UsernameError"){
          // state.login_message = "Something is wrong with your email. Please check it and try again."
          state.message = "Something is wrong with your username. Please check it and try again."
          state.message_level = "error"
        } else if (action.payload === "UsernameExistsError"){
          // state.login_message = "Something is wrong with your email. Please check it and try again."
          state.message = "This username is taken. Please try again."
          state.message_level = "error"
        } else if (action.payload === "WeakPassword"){
          // state.login_message = "Your password is too weak, try again."
          state.message = "Your password is too weak, try again."
          state.message_level = "error"
        }
      })
      .addCase(registerAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(submitResetPasswordAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log("submitResetPasswordAsync.fulfilled", {action})
        
        // state.login_message = "Reset, please login"
        if (action.payload == "bad_code"){
          // state.login_message = "Failed to reset, try again"
          state.message = "Password Reset failed, try again"
          state.message_level = "error"
        } else if (action.payload == "user_not_found") {
          // state.login_message = "Failed to reset, try again"
          state.message = "Password Reset failed, try again"
          state.message_level = "error"
        } else {
          state.message = "Password reset successful, please login"
          state.message_level = "success"
        }
        // console.log("registerAsync", {action})
        // if (action.payload === "FullForDay"){
        //   state.login_message = "We are not accepting new sign-ups right now. Please try again later."
        // } else if (action.payload === "EmailError"){
        //   state.login_message = "Something is wrong with your email. Please check it and try again."
        // } else if (action.payload === "WeakPassword"){
        //   state.login_message = "Your password is too weak, try again."
        // }
      })
  },
});

export const { authenticate, log_out, clear_message } = loginSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
export const selectLoggedIn = (state: RootState) => state.login.logged_in;
export const selectStatus = (state: RootState) => state.login.status;
export const selectUsername = (state: RootState) => state.login.login_username;
export const selectAccount = (state: RootState) => state.login.account;
export const selectFeatureFlags = (state: RootState) => state.login.feature_flags;
export const selectLoginAttempted = (state: RootState) => state.login.login_attempted;
export const selectLoginMessage = (state: RootState) => state.login.login_message;
export const selectMessage = (state: RootState) => state.login.message;
export const selectMessageLevel = (state: RootState) => state.login.message_level;
export const selectFeatureFlagCodeNames = (state: RootState) => state.login.feature_flags?.map((feature_flag) => feature_flag.code_name);

const isFeaturePermitted = (state: RootState, feature_code_name: string) => (selectFeatureFlagCodeNames(state) || []).includes(feature_code_name)
export const selectIsWorkflowDiscordUsernamePermitted = (state: RootState) => isFeaturePermitted(state, 'workflow_discord_username');
// export const selectIsGpt4AccessPermitted = (state: RootState) => isFeaturePermitted(state, 'gpt_4_access');

export default loginSlice.reducer;
