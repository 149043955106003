import { CheckCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';

import { Link } from 'react-router-dom';
import { useDataSets, useInfoSets } from '../app/apiQueries';


import { Popover, Tag, Timeline } from 'antd';
// import { PageHeader } from '@ant-design/pro-layout';

// import 'react-quill/dist/quill.snow.css';
import { useAppSelector } from '../app/hooks';
import {
    Prompt as PromptType,
    WorkflowEmailBlockStep,
    WorkflowPromptBlockStep,
    WorkflowSetProjectSnippetBlockStep, WorkflowSetResultBlockStep,
    Workflow as WorkflowType
} from '../types/types';

import { selectAccount } from '../features/login/loginSlice';

import { modelLabelsByValue } from '../components/WorkflowConfigurationForm';


export function PromptPreview({
    prompt
}: {
    prompt: PromptType
}) {

    return <>
        <Typography.Paragraph>Prompt: <Link to={`/prompt/${prompt.id}/`}>{prompt.name}</Link></Typography.Paragraph>
        <Typography.Paragraph style={{border: '1px solid #555', padding: '10px', margin: "12px 0 24px", fontSize: '0.8rem', whiteSpace: 'pre-line'}}>{prompt.content}</Typography.Paragraph>
    </>


}


export function WorkflowPreview({
    workflow,
    prompts,
}: {workflow: WorkflowType, prompts: PromptType[]}) {
    const account = useAppSelector(selectAccount);


    const {info_sets} = useInfoSets(account);
    const {data_sets} = useDataSets(account);

    const start = <RightCircleOutlined  style={{ color: '#344f97' }} />
    const check = <CheckCircleOutlined  style={{ color: '#8bbb11' }} />

    if (!workflow.config) {
        return <span>Not configured</span>
    }
    const trigger_elem = workflow.config.triggers.map((trigger) => {
        if (trigger.trigger_type === 'form_submit') {
            return <>Form{(trigger.args.submission_params || []).length > 0 || trigger.args.form_title ? ': ' : ''} {trigger.args.form_title ? trigger.args.form_title : ''} {trigger.args.submission_params.map((p) => <Tag>{p.field}</Tag>)}</>
        } else if (trigger.trigger_type === 'timed') {
            if (trigger.args.schedule.type === 'daily'){
                return <>Timed - Daily at {trigger.args.schedule_hour}:00 UTC</>
            } else if (trigger.args.schedule.type === 'weekly'){
                return <>Timed - Weekly ({trigger.args.schedule.week_days.join(", ")}) at {trigger.args.schedule_hour}:00 UTC</>
            } else if (trigger.args.schedule.type === 'monthly'){
                return <>Timed - Monthly ({trigger.args.schedule.month_days.join(", ")}) at {trigger.args.schedule_hour}:00 UTC</>
            }
        }
        else {
            return <>None</>
        }
    })

    const getPromptForStep = (step: WorkflowPromptBlockStep) => {
        const matching_prompts = prompts.filter((p) => p.id === step.args.prompt_id)
        console.log("promptPopoverContent", {step, matching_prompts, prompts})
        if (matching_prompts.length < 1) {
            return null
        }
        return matching_prompts[0]
    }

    const promptPopoverContent = (step: WorkflowPromptBlockStep) => {
        const matching_prompts = prompts.filter((p) => p.id === step.args.prompt_id)
        console.log("promptPopoverContent", {step, matching_prompts, prompts})
        if (matching_prompts.length < 1) {
            return <></>
        }
        return <div style={{width: "500px"}}>
            <PromptPreview prompt={matching_prompts[0]} />

        </div>
    };

    const emailPopoverContent = (step: WorkflowEmailBlockStep) => (
        <div style={{width: "500px"}}>
            {/* <TradingViewChartWidget symbol={symbol} /> */}
            <>
                <Typography.Paragraph>Subject: {step.args.subject_template}</Typography.Paragraph>
                <Typography.Paragraph style={{border: '1px solid #555', padding: '10px', margin: "12px 0 24px", fontSize: '0.8rem', whiteSpace: 'pre-line'}}>{step.args.content_markdown_template}</Typography.Paragraph>
            </>
        </div>
    );

    const setProjectSnippetPopoverContent = (step: WorkflowSetProjectSnippetBlockStep) => (
        <div style={{width: "500px"}}>
            {/* <TradingViewChartWidget symbol={symbol} /> */}
            <>
                <Typography.Paragraph>Snippet: {step.args.snippet_name}</Typography.Paragraph>
                <Typography.Paragraph style={{border: '1px solid #555', padding: '10px', margin: "12px 0 24px", fontSize: '0.8rem', whiteSpace: 'pre-line'}}>{step.args.snippet_content_template}</Typography.Paragraph>
            </>
        </div>
    );

    const setResultPopoverContent = (step: WorkflowSetResultBlockStep) => (
        <div style={{width: "500px"}}>
            {/* <TradingViewChartWidget symbol={symbol} /> */}
            <>
                <Typography.Paragraph>Result</Typography.Paragraph>
                <Typography.Paragraph style={{border: '1px solid #555', padding: '10px', margin: "12px 0 24px", fontSize: '0.8rem', whiteSpace: 'pre-line'}}>{step.args.result_template}</Typography.Paragraph>
            </>
        </div>
    );

    const getLookupParamDescription = (param: string, value: string) => {
        if (param === "data_set_id") {
            const matching_sets = (data_sets || []).filter((s) => s.id === value)
            if (matching_sets.length > 0) {
                return `Data Set = ${matching_sets[0].name}`
            }
            return "Data Set"
        }
        return `${param} = ${value}`
    }

    return <Timeline className={'workflow-preview'}>
        <Timeline.Item color={'blue'} dot={start}>{trigger_elem || 'No Trigger'}</Timeline.Item>
        {(workflow.config.step_definition?.args.block_steps || []).map((step, i) => (
            <Timeline.Item key={i}>
                <Space direction='vertical'>
                    {step.block_step_type === 'execute_prompt' && (
                        <>
                            <span>
                                Prompt <Popover content={promptPopoverContent(step)} mouseEnterDelay={0.8} placement='right'>
                                    <a>{getPromptForStep(step)?.name || 'Prompt'}</a>
                                </Popover> on {modelLabelsByValue[step.args.model] || 'GPT-3.5 by default'}
                            </span>
                            <span>&#8594; {(step.args.set_template_vars || []).map((template_var) => <>{template_var.field} = {template_var.value ? <>'{template_var.value}'</> : <Tag>{template_var.upstream_ref}</Tag>}</>)}</span>
                            {step.args.output_method.output_method_type === 'whole_prompt_response' && (
                                <span>= <Tag>{step.args.output_method.args.set_ref}</Tag></span>
                            )}
                        </>
                    )}
                    {step.block_step_type === 'lookup_info_set' && (
                        <>
                            <span>Lookup</span>
                            <Tag>{(info_sets || []).find((v) => v.id === step.args.info_set_id)?.name || "Unknown Lookup"}
                                {(info_sets || []).find((v) => v.id === step.args.info_set_id)?.enabled ? '' : '(disabled) ' }</Tag>
                            <div style={{ paddingLeft: '12px'}}>

                                {(Object.keys(step.args.source_lookup_params || {})).map((param) => (
                                    <Tag key={param}>{getLookupParamDescription(param, step.args.source_lookup_params[param])}</Tag>
                                ))}
                            </div>

                            {step.args.set_template_vars && (
                                <span>&#8594; {(step.args.set_template_vars || []).map((template_var) => <>{template_var.field} = {template_var.value ? <>'{template_var.value}'</> : <Tag>{template_var.upstream_ref}</Tag>}</>)}</span>

                            )}
                            {step.args.output_method.output_method_type === 'whole_prompt_response' && (
                                <span>= <Tag>{step.args.output_method.args.set_ref}</Tag></span>
                            )}
                        </>
                    )}
                    {step.block_step_type === 'send_email' && (
                        <>
                            <span>
                                Send <Popover content={emailPopoverContent(step)} mouseEnterDelay={0.8} placement='right'>
                                    <a>Email</a>
                                </Popover>
                            </span>
                            <span>&#8594; {(step.args.set_template_vars || []).map((template_var) => <>{template_var.field} = {template_var.value ? <>'{template_var.value}'</> : <Tag>{template_var.upstream_ref}</Tag>}</>)}</span>
                        </>
                    )}
                    {step.block_step_type === 'set_project_snippet' && (
                        <>
                            <span>
                                Set <Popover content={setProjectSnippetPopoverContent(step)} mouseEnterDelay={0.8} placement='right'>
                                    <a>{step.args.snippet_name}</a> Snippet
                                </Popover>
                            </span>
                            <span>&#8594; {(step.args.set_template_vars || []).map((template_var) => <>{template_var.field} = {template_var.value ? <>'{template_var.value}'</> : <Tag>{template_var.upstream_ref}</Tag>}</>)}</span>
                        </>
                    )}
                    {step.block_step_type === 'set_result' && (
                        <>
                            <span>
                                Set <Popover content={setResultPopoverContent(step)} mouseEnterDelay={0.8} placement='right'>
                                    <a>Result</a>
                                </Popover>
                            </span>
                            <span>&#8594; {(step.args.set_template_vars || []).map((template_var) => <>{template_var.field} = {template_var.value ? <>'{template_var.value}'</> : <Tag>{template_var.upstream_ref}</Tag>}</>)}</span>
                        </>
                    )}
                </Space>
            </Timeline.Item>
        ))}
        {((workflow.config.triggers || []).length > 0 && (workflow.config.step_definition?.args.block_steps || [].length > 0)) ? (
            <Timeline.Item color={'green'} dot={check}>Complete</Timeline.Item>

        ) : (
            <Timeline.Item color={'red'}>Not Configured</Timeline.Item>

        )}
    </Timeline>

    // return <span>default</span>
}
