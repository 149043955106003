import { Space, Typography } from 'antd';
import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { CreateProjectTemplateList } from '../../components/CreateProjectTemplateList';
import { ProjectList } from '../../components/ProjectList';


import { useAppSelector } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice';

export function Projects() {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {document.title = 'PyroPrompts | Workflow'}, [])

    const account = useAppSelector(selectAccount);


    return (
        <div className="App">
            <div className="App-content">
                <Typography.Title level={1}>Projects&nbsp;{account && (
                    <Typography.Text style={{fontSize: "0.7rem"}}><Link to={`/my_workflows`}>
                    see all your workflows&nbsp;&#8594;
                    </Link></Typography.Text>
                )}</Typography.Title>
                <div className="App-page-content">
                    {!account && (
                        <>
                            <Typography.Paragraph><a onClick={() => {
                                searchParams.set("auth", "signup")
                                setSearchParams(searchParams)
                            }}>Sign up or Log in</a> to set up your Project for free.</Typography.Paragraph>
                            <Typography.Paragraph>
                                Save time and establish repeatable, automated AI procedures for consistent quality with No-Code Project Workflows and Snippets.
                            </Typography.Paragraph>
                            <Typography.Paragraph>Projects contain all your AI Workflows (processes) and reusable snippets for your project.</Typography.Paragraph>
                            <Typography.Paragraph>If you're running a blog, you could have Snippets for explaining your brand, audience and SEO Keywords and reuse those across multiple prompts at a click of a button.</Typography.Paragraph>
                            <Typography.Paragraph>Start automating for free in less than 20 minutes without writing any code. <a onClick={() => {
                                searchParams.set("auth", "signup")
                                setSearchParams(searchParams)
                            }}>Sign up or Log in</a> to start.</Typography.Paragraph>
                        </>
    
                    )}
                    {account && (
                        <Space direction="vertical">
                            <Typography.Paragraph>
                                Save time and establish repeatable, automated AI procedures for consistent quality with No-Code Project Workflows and Snippets.
                            </Typography.Paragraph>
                            {account.name === 'ferrants' && (
                                <CreateProjectTemplateList onCreate={() => {}} style={{marginBottom: '12px'}} />

                            )}

                            <ProjectList />
                        </Space>
                    )}
                    
                </div>
            </div>
        </div>
    );
}
