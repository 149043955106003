import { Button, Divider, Radio, Carousel, Popconfirm, Pagination, message, List, Spin, ConfigProvider, Empty, Typography, Avatar, Descriptions, Tag as AntTag, Collapse, Card } from 'antd';
import { useState, useEffect } from 'react';
// import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { ArrowsAltOutlined, ReloadOutlined, UpCircleOutlined, DownCircleOutlined, PauseCircleOutlined, LoadingOutlined, CopyOutlined, PlayCircleOutlined, DeleteOutlined, CheckCircleOutlined, InfoCircleOutlined, IssuesCloseOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import type { RadioChangeEvent } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { toTitleCase } from '../utils'

import { DateTime } from 'luxon';

import { Tooltip } from 'antd';
import { WorkflowExecution } from '../types/types';


import { useAppSelector } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';
import { getWorkflowExecutions, getWorkflowExecutionsLoggedOut } from '../services/signalApi';
import { CopyToClipboard } from './CopyToClipboard';
import { ToggleToMarkdown } from './ToggleToMarkdown';




interface WorkflowExecutionResultParams {
    workflow_execution_id: string
}


export function WorkflowExecutionResult({
    workflow_execution_id
}: WorkflowExecutionResultParams) {
    console.log("WorkflowExecutionsList")
    const account = useAppSelector(selectAccount)
    const [loadedWorkflowExecution, setLoadedWorkflowExecution] = useState<WorkflowExecution | null>(null)
    const [isLoading, setIsLoading] = useState(false);
    const [reloadedLast, setReloadedLast] = useState('never');
    const [reloads, setReloads] = useState(0);

    const check = <CheckCircleOutlined  style={{ color: '#8bbb11' }} />
    const waiting = <Tooltip title="Running"  placement="left"><IssuesCloseOutlined  style={{ color: '#e8b339' }} /></Tooltip>
    const blocked = <Tooltip title="Failed"  placement="left"><IssuesCloseOutlined  style={{ color: '#d32029' }} /></Tooltip>
    const optional = <RightCircleOutlined  style={{ color: '#3c9ae8' }} />
    const loading = <LoadingOutlined spin />;
    const spin_loading = <Spin indicator={loading} />

    useEffect(() => {
        setIsLoading(true)
        const getWorkflowExecutionsFn = account ? getWorkflowExecutions : getWorkflowExecutionsLoggedOut
        getWorkflowExecutionsFn({
            id: workflow_execution_id,
            ordering: '-created_date'
        }).then((res) => {
            setIsLoading(false)
            console.log("WorkflowExecutionsList Workflows", {res})
            if (res.status === 200) {
                const results = res.data.results
                if (results && results.length > 0) {
                    setLoadedWorkflowExecution(results[0])
                    if (results[0].status && ['running', 'enqueued'].includes(results[0].status)) {
                        setTimeout(triggerReload, 2000)
                    }
                }

            }
        }).catch(() => {
            // setTotalWorkflowExecutions(null)
            // setLoadedWorkflowExecutions([])

        })
    }, [account, reloadedLast, workflow_execution_id])


    const getExecutionIcon = () => {
        if (loadedWorkflowExecution === null) {
            return <></>
        }
        if (loadedWorkflowExecution.status === 'running') {
            return <>{spin_loading}&nbsp;&nbsp;</>
        }
        if (loadedWorkflowExecution.status === 'enqueued') {
            return <>{spin_loading}&nbsp;&nbsp;</>
        }
        if (loadedWorkflowExecution.status === 'complete') {
            return <>{check}&nbsp;&nbsp;</>
        }
        if (loadedWorkflowExecution.status === 'failed') {
            return <>{blocked}&nbsp;&nbsp;</>
        }
        return <></>
    }



    const execution_result_content = () => {
        return <>
            {loadedWorkflowExecution && loadedWorkflowExecution.result && (
                <>

                    {loadedWorkflowExecution.result && (
                        <div className='hover-copy-content'>
                            <div className='hover-copy-button-wrapper'>
                                <CopyToClipboard text={loadedWorkflowExecution.result || ''} tooltip='Copy to Clipboard' content={<Button><CopyOutlined /></Button>} />
                            </div>
                            <Typography.Title level={4}>{getExecutionIcon()}Response:</Typography.Title>
                            <ToggleToMarkdown markdown={loadedWorkflowExecution.result || '-'} />
                        </div>
                    )}

                </>
            )}
            {loadedWorkflowExecution && !loadedWorkflowExecution.result && (
                <Typography.Paragraph>{getExecutionIcon()} {toTitleCase(loadedWorkflowExecution.status || '')}</Typography.Paragraph>
            )}
        </>
    }

    const triggerReload = () => {
        const dt = DateTime.now();
        setReloadedLast(dt.toISO())
    }

    const buttons = (
        <Space style={{marginBottom: '6px', float: 'right'}}>
            <Button type="default" onClick={triggerReload}><ReloadOutlined /></Button>
        </Space>
    )

    return (
        <>
            {/* {buttons} */}
            {execution_result_content()}
        </>
    )
    
    
    
}
